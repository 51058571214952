import gql from 'graphql-tag';

const EVENT_OVERVIEW_QUERY = gql`
  query EventOverview($filters: EventFilterInput) {
    currentVendor {
      id
      name
    }
    currentActor {
      id
      name
      events(filters: $filters) {
        edges {
          node {
            id
            isUpdatableByCurrentActor
            currentEventVendor {
              id
              isAdmin
              mergeableEvents {
                id
                name
              }
            }
            currentEventHostActor {
              id
              isAdmin
              mergeableEvents {
                id
                name
              }
            }
            currentActorTaskOverview {
              id
              unreadMentionsCount
              createdBy {
                id
                overdueCount
                notStartedCount
                inProgressCount
              }
              assignedTo {
                id
                overdueCount
                notStartedCount
                inProgressCount
              }
            }
            currentVendorTaskOverview {
              id
              unreadMentionsCount
              createdBy {
                id
                overdueCount
                notStartedCount
                inProgressCount
              }
              assignedTo {
                id
                overdueCount
                notStartedCount
                inProgressCount
              }
            }
            eventHostActors {
              id
              status
              isAdmin
              notes
              hostActor {
                id
                name
                initials
                avatarUrl
                email
                phoneNumber
                instagramHandle
              }
            }
          }
        }
      }
    }
  }
`;

const EVENT_HOST_ACTOR_CREATE_MUTATION = gql`
  mutation CreateEventHostActor($input: EventHostActorCreateMutationInput!) {
    eventHostActorCreate(input: $input) {
      eventHostActor {
        id
      }
    }
  }
`;

const EVENT_HOST_ACTOR_UPDATE_MUTATION = gql`
  mutation UpdateEventHostActor($input: EventHostActorUpdateMutationInput!) {
    eventHostActorUpdate(input: $input) {
      eventHostActor {
        id
      }
    }
  }
`;

const EVENT_INVITE_UPDATE_MUTATION = gql`
  mutation UpdateEventInvite($input: EventInviteUpdateMutationInput!) {
    eventInviteUpdate(input: $input) {
      eventInvite {
        id
        status
        event {
          id
        }
      }
    }
  }
`;

export {
  EVENT_OVERVIEW_QUERY,
  EVENT_HOST_ACTOR_CREATE_MUTATION,
  EVENT_HOST_ACTOR_UPDATE_MUTATION,
  EVENT_INVITE_UPDATE_MUTATION,
};
