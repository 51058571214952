import React from 'react';
import { useController } from 'react-hook-form';
import { InputMask } from 'primereact/inputmask';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { classNames } from 'primereact/utils';

import { mergeClassNames } from 'utils/styleUtils';

function PhoneNumberInput(props) {
  const {
    control, name, label, className, controllerProps, inputProps,
  } = props;

  const PHONE_NUMBER_REGEX = /^\+?[1]?[\s|-]?\(?([2-9]{1}[1-9]{2})\)?[\s|-]?(\d{3})[\s|-]?(\d{4})$/;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: false,
      pattern: PHONE_NUMBER_REGEX,
    },
    defaultValue: '',
    ...controllerProps,
  });

  const inputClasses = classNames(
    'w-full',
    {
      'p-invalid': error,
    },
  );

  const renderPhoneNumberError = (fieldError) => {
    if (!fieldError) { return null; }

    let errorMessage = '';
    switch (fieldError.type) {
      case 'required':
        errorMessage = 'Phone number is required';
        break;
      case 'pattern':
        errorMessage = 'Invalid phone number. E.g. (954) 555-1234';
        break;
      default:
        break;
    }

    return <p className="text-danger mt-2">{errorMessage}</p>;
  };

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className="block mb-2">{label}</label> }
      <IconField>
        <InputIcon className="pi pi-phone" />
        <InputMask
          id={name}
          className={inputClasses}
          mask="(999) 999-9999"
          value={value}
          maxLength="14"
          onChange={onChange}
          {...inputProps}
        />
      </IconField>
      { renderPhoneNumberError(error) }
    </div>
  );
}

export default PhoneNumberInput;
