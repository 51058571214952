import React, { useRef, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { renderFormFieldError } from 'utils/formUtils';
import { mergeClassNames } from 'utils/styleUtils';

function TextInput(props) {
  const {
    control,
    name,
    placeholder,
    label,
    required = true,
    renderError = true,
    labelClassName = '',
    inputClassName = '',
    className,
    onBlur = () => {},
    controllerProps,
    focus = false,
    inputProps,
  } = props;
  const inputRef = useRef();

  useEffect(() => {
    if (focus) { inputRef?.current?.focus(); }
  }, [inputRef?.current]);

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: '',
    ...controllerProps,
  });

  const inputClasses = classNames(
    'block',
    'w-full',
    {
      'p-invalid': error,
    },
    inputClassName,
  );

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className={`block mb-2 ${labelClassName}`}>{label}</label> }
      <InputText
        id={name}
        onBlur={onBlur}
        ref={inputRef}
        placeholder={placeholder}
        value={value || ''}
        onChange={onChange}
        className={inputClasses}
        {...inputProps}
      />
      { renderError && renderFormFieldError(error) }
    </div>
  );
}

export default TextInput;
