import React from 'react';
import { useController } from 'react-hook-form';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';

import { renderFormFieldError } from 'utils/formUtils';
import { mergeClassNames } from 'utils/styleUtils';

function NumberInput(props) {
  const {
    control, name, required = true, label, labelClassName, className, controllerProps, inputProps,
  } = props;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: null,
    ...controllerProps,
  });

  const inputClasses = classNames(
    'w-full',
    {
      'p-invalid': error,
    },
  );

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className={`block mb-2 ${labelClassName}`}>{label}</label> }
      <InputNumber
        id={name}
        className={inputClasses}
        value={value}
        onValueChange={onChange}
        mode="decimal"
        useGrouping={false}
        {...inputProps}
      />
      { renderFormFieldError(error) }
    </div>
  );
}

export default NumberInput;
