import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import reduce from 'lodash/reduce';
import { Button } from 'primereact/button';
import MultiSelect from 'components/Form/MultiSelect';
import DropdownField from 'components/Form/Dropdown';
import { actorItemTemplate } from 'utils/formUtils';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { titleize } from 'utils/stringUtils';
import { UPDATE_EVENT_VENDOR_MUTATION } from './graphql';

function UpdateEventVendorForm({
  currentVendor, eventVendor, refetchEventVendors, hideModal, toastRef,
}) {
  const {
    control, handleSubmit, setValue, reset,
  } = useForm();

  useEffect(() => {
    setValue('categories', eventVendor.categories);
    setValue(
      'defaultTaskAssigneeActorId',
      eventVendor.defaultTaskAssigneeActor.id || currentVendor.defaultTaskAssigneeActor.id,
    );
    setValue(
      'primaryPointOfContactActorId',
      eventVendor.primaryPointOfContactActor.id || currentVendor.primaryPointOfContactActor.id,
    );
    setValue('secondaryPointOfContactActorId', eventVendor.secondaryPointOfContactActor?.id);
    setValue('dayOfPointOfContactActorId', eventVendor.dayOfPointOfContactActor?.id);
  }, [eventVendor]);

  const allCategories = reduce(eventVendor.vendor.categories, (options, category) => {
    options.push({ label: titleize(category), value: category });
    return options;
  }, []);

  const allActors = (currentVendor.actors?.edges || []).map(({ node }) => ({
    label: node.name, value: node.id, name: node.name, initials: node.initials, avatarUrl: node.avatarUrl,
  }));

  const [updateEventVendorMutation] = useMutation(UPDATE_EVENT_VENDOR_MUTATION, {
    onCompleted: async () => {
      hideModal();
      reset();
      await refetchEventVendors({ id: eventVendor.id });
      showSuccessToast(toastRef, 'Successfully updated vendor info');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const onSubmit = (values) => {
    updateEventVendorMutation({
      variables: {
        input: { id: eventVendor.id, ...values },
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex lg:grid lg:grid-cols-2 gap-2 mt-0 items-center">
          <MultiSelect
            control={control}
            name="categories"
            label="Categories"
            options={allCategories}
            placeholder="Select a category"
          />
          <DropdownField
            control={control}
            name="defaultTaskAssigneeActorId"
            label="Default Task Assignee"
            options={allActors}
            dropdownClassName="h-12"
            inputProps={{
              placeholder: 'Select a default task assignee',
              itemTemplate: (option) => actorItemTemplate({ option }),
              valueTemplate: (option) => actorItemTemplate({ option, placeholder: 'Select a default task assignee' }),
            }}
          />
        </div>
        <p className="font-semibold mt-6 mb-4">Event Contacts</p>
        <div className="flex xxs:grid-cols-1 grid lg:grid-cols-3 gap-2 items-center mt-2">
          <DropdownField
            control={control}
            name="primaryPointOfContactActorId"
            dropdownClassName="h-12"
            label="Primary"
            options={allActors}
            inputProps={{
              placeholder: 'Select a primary point of contact',
              itemTemplate: (option) => actorItemTemplate({ option }),
              valueTemplate: (option) => actorItemTemplate({ option, placeholder: 'Select a primary point of contact actor' }),
            }}
          />
          <DropdownField
            control={control}
            name="secondaryPointOfContactActorId"
            dropdownClassName="h-12"
            label="Secondary"
            required={false}
            options={allActors}
            inputProps={{
              placeholder: 'Select a secondary point of contact',
              itemTemplate: (option) => actorItemTemplate({ option }),
              valueTemplate: (option) => actorItemTemplate({ option, placeholder: 'Select a secondary point of contact actor' }),
            }}
          />
          <DropdownField
            control={control}
            name="dayOfPointOfContactActorId"
            dropdownClassName="h-12"
            label="Day Of"
            required={false}
            options={allActors}
            inputProps={{
              placeholder: 'Select a day of point of contact',
              itemTemplate: (option) => actorItemTemplate({ option }),
              valueTemplate: (option) => actorItemTemplate({ option, placeholder: 'Select a day of point of contact actor' }),
            }}
          />
        </div>
        <div className="flex justify-end p-0 grid grid-cols-2 mt-4">
          <Button
            key="submit-email-button"
            size="small"
            className="xxs:col-span-3 lg:col-span-1 lg:col-start-4"
            label="Update"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}

export default UpdateEventVendorForm;
