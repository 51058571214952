import React, { useRef, useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { useParams } from 'react-router';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import map from 'lodash/map';
import compact from 'lodash/compact';
import identity from 'lodash/identity';
import reduce from 'lodash/reduce';
import without from 'lodash/without';
import { showErrorToast } from 'utils/toastUtils';
import Filters from 'components/Filters';
import CreateTaskModal from './CreateTaskForm/Modal';
import ManageTemplatesModal from './ManageTemplatesForm/Modal';
import ManageLabelsModal from './ManageLabelsForm/Modal';
import ManageDisplaySettings from './ManageDisplaySettings';
import TasksIndexView from './View';
import {
  EVENT_VENDORS_QUERY, TASKS_QUERY, TEMPLATES_FETCH_QUERY, LABELS_FETCH_QUERY,
} from './graphql';

const MAX_ASSIGNEE_COLUMNS = 10;
const DEFAULT_DISPLAY_SETTINGS = {
  grouping: 'assignee', showEmptyGroups: false, showArchived: false, showCompleted: false,
};
const NO_MATCHING_TASKS = "No tasks match the selected filters. Double check your display settings if you can't find what you're looking for.";
function Tasks({
  canCreateTasks, canUpdateLabels, canUpdateTemplates,
}) {
  const [unreadMentionsCount, setUnreadMentionsCount] = useState(0);
  const [filteringMentions, setFilteringMentions] = useState(localStorage.getItem('filteringTaskMentions') || false);
  const [fetchingTasks, setFetchingTasks] = useState(true);
  const [filterParams, setFilterParams] = useState({});
  const [showNoMatchingErrorMessage, setShowNoMatchingErrorMessage] = useState(false);
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [subtaskTemplates, setSubtaskTemplates] = useState([]);
  const [labels, setLabels] = useState([]);
  const [searchTerm, setSearchTerm] = useState(false);
  const [eventVendors, setEventVendors] = useState([]);
  const [eventHostActors, setEventHostActors] = useState([]);
  const [mappedActors, setMappedActors] = useState([]);
  const [actors, setActors] = useState([]);
  const [taskFilters, setTaskFilters] = useState(JSON.parse(localStorage.getItem('taskFilters')) || {});
  const [displaySettings, setDisplaySettings] = useState(JSON.parse(localStorage.getItem('taskDisplaySettings')) || DEFAULT_DISPLAY_SETTINGS);
  const [showFilters, setShowFilters] = useState(false);
  const [currentVendor, setCurrentVendor] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState(JSON.parse(localStorage.getItem('selectedTaskFilters')) || []);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [showManageTemplatesModal, setShowManageTemplatesModal] = useState(false);
  const [showManageLabelsModal, setShowManageLabelsModal] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [currentActor, setCurrentActor] = useState({});
  const { eventId, taskId, templateId } = useParams();
  const toastRef = useRef(null);
  const filtersButtonRef = useRef(null);
  const displaySettingsRef = useRef(null);

  const setSelectedGrouping = (grouping) => {
    if (grouping === 'assignee' && actors.count > MAX_ASSIGNEE_COLUMNS) {
      setDisplaySettings({ ...displaySettings, grouping, showEmptyGroups: false });
    } else {
      setDisplaySettings({ ...displaySettings, grouping });
    }
  };

  const setShowCompleted = (showCompleted) => {
    setDisplaySettings({ ...displaySettings, showCompleted });
  };

  const setShowArchived = (showArchived) => {
    setDisplaySettings({ ...displaySettings, showArchived });
  };

  const setShowEmptyGroups = (showEmptyGroups) => {
    setDisplaySettings({ ...displaySettings, showEmptyGroups });
  };

  useEffect(() => (
    filteringMentions ? (
      window.localStorage.setItem('filteringTaskMentions', filteringMentions)
    ) : (
      window.localStorage.removeItem('filteringTaskMentions')
    )
  ), [filteringMentions]);

  const mapActorOption = ({ actor = {}, vendor = {} }) => ({
    id: actor.id,
    uuid: actor.uuid,
    name: actor.name,
    initials: actor.initials,
    avatarUrl: actor.avatarUrl,
    label: actor.name,
    value: actor.id,
    vendor,
  });

  const assigneeOptions = ({ eventVendor }) => {
    if (eventVendor.vendor.id !== currentVendor?.id) {
      const { vendor } = eventVendor;

      return mapActorOption({ vendor });
    }

    return actors.map((actor) => mapActorOption({ actor, group: 'actor', vendor: currentVendor }));
  };

  const groupingOptions = [
    {
      value: 'status',
      label: 'Status',
    },
    {
      value: 'assignee',
      label: 'Assigned To',
    },
    {
      value: 'label',
      label: 'Label',
    },
  ];

  const mapActors = () => {
    const hostActorOptions = compact(eventHostActors.map((eventHostActor) => mapActorOption({ actor: eventHostActor.hostActor })));

    if (!eventVendors.length) {
      if (currentVendor) {
        return ([{
          value: currentVendor.id,
          label: currentVendor.name,
          items: actors.map((actor) => mapActorOption({ actor, vendor: currentVendor })),
        }]);
      }
      return ([{
        value: currentActor.id,
        label: currentActor.name,
        items: actors.map((actor) => mapActorOption({ actor })),
      }]);
    }

    return reduce(eventVendors, (mappedAssigneeOptions, eventVendor) => {
      const isCurrentVendor = eventVendor.vendor.id === currentVendor?.id;

      if (isCurrentVendor) {
        mappedAssigneeOptions.unshift({
          value: currentVendor.id,
          label: currentVendor.name,
          items: assigneeOptions({ eventVendor }),
        });
      } else {
        const othersOption = mappedAssigneeOptions.find((option) => option.value === 'other-vendors');
        othersOption.items.push(assigneeOptions({ eventVendor }));
      }

      return mappedAssigneeOptions;
    }, [
      { value: 'other-vendors', label: 'Vendors', items: [] },
      { value: 'hosts', label: 'Hosts', items: hostActorOptions },
    ]).filter((group) => group.items.length);
  };

  useEffect(() => {
    if (currentVendor || currentActor) { setMappedActors(mapActors); }
  }, [currentVendor, currentActor, eventVendors]);

  const {
    refetch: refetchTemplates,
  } = useQuery(
    TEMPLATES_FETCH_QUERY,
    {
      onCompleted: (data) => {
        setTaskTemplates(data.currentActor?.taskTemplates || []);
        setSubtaskTemplates(data.currentActor?.subtaskTemplates || []);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchAndSetTemplates = async () => {
    const response = await refetchTemplates();

    setTaskTemplates(response.data.currentActor?.taskTemplates || []);
    setSubtaskTemplates(response.data.currentActor?.subtaskTemplates || []);
  };

  const {
    refetch: refetchLabels,
  } = useQuery(
    LABELS_FETCH_QUERY,
    {
      onCompleted: (data) => {
        setLabels(data.currentActor?.taskLabels || []);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const [
    fetchTasks,
    {
      error: tasksQueryError,
      refetch,
    },
  ] = useLazyQuery(
    TASKS_QUERY,
    {
      variables: {
        taskFilters: pickBy(merge(filterParams, { eventId, titleIncludes: searchTerm }), identity),
      },
      onCompleted: (data) => {
        setFetchingTasks(false);
        setCurrentActor(data.currentActor);
        setCurrentVendor(data.currentVendor);
        setTasks(map(data.currentActor.tasks.edges, 'node'));
        setActors(map(data.currentVendor?.taskActors?.edges || [], 'node'));
        setUnreadMentionsCount(data.currentActor.unreadTaskMentionsCount);
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const [fetchEventVendors] = useLazyQuery(
    EVENT_VENDORS_QUERY,
    {
      variables: {
        eventFilters: { id: eventId },
        eventHostActorFilters: { status: ['APPROVED', 'INVITED'] },
      },
      onCompleted: (data) => {
        setEventVendors(data.currentActor.events.edges[0].node.eventVendors);
        setEventHostActors(data.currentActor.events.edges[0].node.eventHostActors);
      },
      onError: (mutationError) => {
        const { graphQLErrors, message } = mutationError;

        if (message) {
          showErrorToast(toastRef, mutationError.message);
        } else {
          graphQLErrors.map(({ message: gqlError }) => (
            showErrorToast(toastRef, gqlError)
          ));
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const clearFilters = () => {
    setSelectedKeys({});
    setFetchingTasks(true);
    setFilteringMentions(false);
  };

  useEffect(() => {
    let onNewPage;

    if (eventId) {
      onNewPage = localStorage.getItem('eventId') !== eventId;
      fetchEventVendors();
    } else {
      onNewPage = localStorage.getItem('eventId') !== 'undefined';
    }

    if (onNewPage) {
      window.localStorage.setItem('eventId', eventId);
      clearFilters();
    }

    fetchTasks();
  }, [eventId]);

  const mapFilterParams = () => {
    if (filterParams === taskFilters) { return {}; }

    const newFilters = structuredClone(taskFilters);

    if (newFilters.status?.length) {
      if (!displaySettings.showCompleted && newFilters.status[0] === 'COMPLETED') {
        setShowNoMatchingErrorMessage(true);
        return {};
      }
    } else {
      newFilters.status = ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED'];

      if (!displaySettings.showCompleted) {
        newFilters.status = without((newFilters?.status || []), 'COMPLETED');
      }
    }

    if (newFilters.archiveStatus?.length) {
      if (!displaySettings.showArchived && newFilters.archiveStatus[0] === 'ARCHIVED') {
        setShowNoMatchingErrorMessage(true);
        return {};
      }
    } else {
      newFilters.archiveStatus = ['NOT_ARCHIVED', 'ARCHIVED'];

      if (!displaySettings.showArchived) {
        newFilters.archiveStatus = without((newFilters?.archiveStatus || []), 'ARCHIVED');
      }
    }

    setShowNoMatchingErrorMessage(false);
    setFilterParams(newFilters);
    return {};
  };

  useEffect(() => {
    window.localStorage.setItem('taskDisplaySettings', JSON.stringify(displaySettings));
    window.localStorage.setItem('taskFilters', JSON.stringify(omit(taskFilters, 'eventId')));
    mapFilterParams();
    fetchTasks();
  }, [taskFilters, displaySettings]);

  const mapActorFilterOptions = (actorType) => (
    mappedActors.map(({
      value, label, items = [],
    }, idx) => {
      if (items.length) {
        return {
          key: `filter-type-actors-${idx}`,
          label,
          className: 'scrollable-filter',
          icon: 'pi pi-building',
          children: items.map((item) => ({ key: `${actorType}ActorId-${item.value}`, label: item.label, icon: 'pi pi-user' })),
        };
      }
      return {
        key: `${actorType}ActorId-${value}`,
        label,
        icon: 'pi pi-user',
      };
    })
  );

  const mapLabelFilterOptions = () => (
    labels.map(({ id, name, colorHexCode }) => ({
      key: `labelId-${id}`,
      label: name,
      colorHexCode,
    }))
  );

  const containerClassNames = classNames(
    { content: !eventId },
    { 'content-with-nav-bar': eventId },
    'no-scroll',
    'md:pt-12',
    'pt-8',
    'task-index-wrapper',
  );

  const tasksIndexView = () => {
    if (showNoMatchingErrorMessage) {
      return <p className="mt-8">{NO_MATCHING_TASKS}</p>;
    }

    if (!currentActor) { return null; }

    if (tasks.length || taskId || templateId) {
      return (
        <div className={containerClassNames}>
          <TasksIndexView
            labels={labels}
            actors={actors}
            subtaskTemplates={subtaskTemplates}
            showEmptyGroups={displaySettings.showEmptyGroups}
            showCompleted={displaySettings.showCompleted}
            selectedGrouping={displaySettings.grouping}
            currentVendor={currentVendor}
            toastRef={toastRef}
            actorOptions={mappedActors}
            eventVendors={eventVendors}
            showEventInfo={!eventId}
            tasks={tasks}
            eventId={eventId}
            currentActor={currentActor}
            refetchTasks={refetch}
            fetchEventVendors={fetchEventVendors}
            refetchTemplates={refetchAndSetTemplates}
          />
        </div>
      );
    }

    if (fetchingTasks) { return null; }

    return <p className="mt-8">{NO_MATCHING_TASKS}</p>;
  };

  const filterItemTemplate = (node) => {
    if (node.key.startsWith('labelId')) {
      return (
        <div className="flex items-center">
          <Tag style={{ backgroundColor: node.colorHexCode }} value={node.label} />
        </div>
      );
    }

    if (node.key.startsWith('archiveStatus')) {
      if (node.value === 'ARCHIVED' && !displaySettings.showArchived) {
        return (
          <div className="flex items-center">
            <i className="pi pi-eye-slash mr-1" />
            <p className="italic">{node.label}</p>
          </div>
        );
      }
    }

    if (node.key.startsWith('status')) {
      if (node.value === 'COMPLETED' && !displaySettings.showCompleted) {
        return (
          <div className="flex items-center">
            <i className="pi pi-eye-slash mr-1" />
            <p className="italic">{node.label}</p>
          </div>
        );
      }

      return (
        <div className="flex items-center">
          <Badge value="" className={`status-${node.value}-bg h-4 w-4 min-w-0 mr-1`} />
          <p>{node.label}</p>
        </div>
      );
    }

    return (
      <div className="flex items-center">
        <p>{node.label}</p>
      </div>
    );
  };

  const toggleFilteringMentions = () => {
    if (filteringMentions) {
      clearFilters();
    } else {
      setFilteringMentions(true);
      setSelectedKeys({ 'mentions-UNREAD': { checked: true } });
    }
  };

  const labelFilter = () => {
    if (!labels.length) { return {}; }

    return ({
      key: 'filter-type-label',
      className: 'scrollable-filter',
      label: 'Label',
      icon: 'pi pi-tag',
      children: mapLabelFilterOptions(),
    });
  };

  const unreadMentionsFilterButton = () => {
    if (!unreadMentionsCount) {
      if (filteringMentions) {
        return toggleFilteringMentions();
      }
      return null;
    }

    let label;
    let icon;

    if (!filteringMentions) {
      label = `${unreadMentionsCount} unread mention${unreadMentionsCount > 1 ? 's' : ''}`;
      icon = 'fa-regular fa-comment-dots';
    } else {
      label = 'Clear filters';
      icon = 'pi pi-filter-slash';
    }

    return (
      <Button type="button" label={label} icon={icon} outlined severity="info" onClick={toggleFilteringMentions} />
    );
  };

  const filterOptions = [
    {
      key: 'filter-type-status',
      label: 'Status',
      children: [
        { key: 'status-NOT_STARTED', label: 'Not Started', value: 'NOT_STARTED' },
        { key: 'status-IN_PROGRESS', label: 'In Progress', value: 'IN_PROGRESS' },
        { key: 'status-COMPLETED', label: 'Completed', value: 'COMPLETED' },
      ],
    },
    {
      key: 'filter-type-mentions',
      label: 'Mentions',
      children: [
        {
          key: 'mentions-READ', label: 'Read', value: 'READ', icon: 'fa-regular fa-envelope-open',
        },
        {
          key: 'mentions-UNREAD', label: 'Unread', value: 'UNREAD', icon: 'fa-regular fa-envelope',
        },
      ],
    },
    { ...labelFilter() },
    {
      key: 'filter-type-assigned-to',
      label: 'Assigned To',
      icon: 'pi pi-user',
      children: mapActorFilterOptions('assignedTo'),
    },
    {
      key: 'filter-type-created-by',
      label: 'Created By',
      icon: 'pi pi-user',
      children: mapActorFilterOptions('createdBy'),
    },
    {
      key: 'filter-type-archive-status',
      label: 'Archive Status',
      children: [
        { key: 'archiveStatus-NOT_ARCHIVED', label: 'Not Archived', value: 'NOT_ARCHIVED' },
        { key: 'archiveStatus-ARCHIVED', label: 'Archived', value: 'ARCHIVED' },
      ],
    },
  ].filter((item) => item.key);

  const onCloseManageLabelsModal = () => {
    refetch();
    setShowManageLabelsModal(false);
  };

  if (tasksQueryError || !currentActor) { return null; }

  return (
    <>
      <Toast ref={toastRef} position="bottom-left" style={{ zIndex: '10000' }} />
      <div className="flex justify-between">
        <div className="flex items-center">
          <p className="font-semibold text-xl">Tasks</p>
          <Button
            ref={filtersButtonRef}
            rounded
            text
            className="h-8 w-8 mx-1"
            icon="pi pi-filter"
            onClick={() => { setShowFilters(!showFilters); }}
          />
          { unreadMentionsFilterButton() }
        </div>
        <div className="flex items-center task-board--options md:mt-0 md:ml-0">
          <IconField className="mr-2">
            <InputIcon className="pi pi-search" />
            <InputText placeholder="Find in view by title..." onInput={({ target }) => setSearchTerm(target.value)} />
          </IconField>
          <Button
            rounded
            className="h-8 w-8 mr-1 manage-display-settings-button"
            icon="pi pi-sliders-h"
            data-pr-tooltip="Manage Display Settings"
            data-pr-position="top"
            onClick={(e) => displaySettingsRef?.current?.toggle(e)}
          />
          <OverlayPanel ref={displaySettingsRef}>
            <ManageDisplaySettings
              showEmptyGroupsToggle={displaySettings.grouping !== 'assignee' || actors.length <= MAX_ASSIGNEE_COLUMNS}
              groupingOptions={groupingOptions}
              displaySettings={displaySettings}
              setSelectedGrouping={setSelectedGrouping}
              setShowEmptyGroups={setShowEmptyGroups}
              setShowCompleted={setShowCompleted}
              setShowArchived={setShowArchived}
            />
          </OverlayPanel>
          <Tooltip target=".manage-display-settings-button" />
          {
            !eventId && (
              <>
                <Button
                  visible={canUpdateLabels}
                  rounded
                  className="h-8 w-8 mr-1 manage-labels-button"
                  icon="pi pi-tag"
                  data-pr-tooltip="Manage Labels"
                  data-pr-position="top"
                  onClick={() => setShowManageLabelsModal(true)}
                />
                <Tooltip target=".manage-labels-button" />
                <Tooltip target=".manage-templates-button" />
                <Button
                  visible={canUpdateTemplates}
                  rounded
                  className="h-8 w-8 mr-1 manage-templates-button"
                  icon="fa-regular fa-note-sticky"
                  data-pr-tooltip="Manage Templates"
                  data-pr-position="top"
                  onClick={() => setShowManageTemplatesModal(true)}
                />
              </>
            )
          }
          <Button
            visible={canCreateTasks}
            rounded
            className="h-8 w-8"
            icon="pi pi-plus"
            onClick={() => setShowCreateTaskModal(true)}
          />
        </div>
      </div>
      <Filters
        filtersButtonRef={filtersButtonRef}
        onClearFilters={clearFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        filterOptions={filterOptions}
        filterItemTemplate={filterItemTemplate}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        setFilters={setTaskFilters}
        selectedKeysLocalStorageName="selectedTaskFilters"
        filtersLocalStorageName="taskFilters"
      />
      <CreateTaskModal
        labels={labels}
        currentVendorName={currentVendor?.name}
        templates={taskTemplates}
        refetch={refetch}
        actionType="create"
        currentActorId={currentActor.id}
        actorOptions={mappedActors}
        show={showCreateTaskModal}
        onHide={() => setShowCreateTaskModal(false)}
      />
      <ManageLabelsModal
        labels={labels}
        refetchLabels={refetchLabels}
        show={showManageLabelsModal}
        onHide={onCloseManageLabelsModal}
      />
      <ManageTemplatesModal
        taskTemplates={taskTemplates}
        subtaskTemplates={subtaskTemplates}
        refetchTemplates={refetchAndSetTemplates}
        show={showManageTemplatesModal}
        onHide={() => setShowManageTemplatesModal(false)}
      />
      { tasksIndexView() }
    </>
  );
}

export default Tasks;
