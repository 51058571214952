import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';

import LazyPaginatedTable from 'components/Table/LazyPaginatedTable';
import { emailLink, phoneLink, instagramLink } from 'utils/stringUtils';
import AvatarWithName from 'components/AvatarWithName';
import CreateOrUpdateTeamMemberModal from '../CreateOrUpdateTeamMemberForm/Modal';

function TeamMemberIndexTable(props) {
  const [showCreateOrUpdateTeamMemberActorModal, setShowCreateOrUpdateTeamMemberActorModal] = useState(false);
  const [selectedTeamMemberActor, setSelectedTeamMemberActor] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);
  const {
    paginationBounds,
    setPaginationBounds,
    currentPage,
    setCurrentPage,
    teamMemberActorData,
    setFilters,
    refetchTeamMembersAndGroups,
    toastRef,
    currentVendorName,
    pageSize,
  } = props;

  useEffect(() => {
    setFilters({ name: { includes: searchTerm } });
  }, [searchTerm]);

  if (!teamMemberActorData.edges.length) { return <p className="mt-2">You don't have any team members yet.</p>; }

  const handleRowClick = ({ data }) => {
    setShowCreateOrUpdateTeamMemberActorModal(true);
    setSelectedTeamMemberActor(data);
  };

  const mapRoles = (teamMemberActor) => {
    const mappedRoles = teamMemberActor.roles.map((role) => (
      <Tag severity="info" className="mr-1" key={`${teamMemberActor.id}-${role.id}`} value={role.name} />
    ));

    return <div>{mappedRoles}</div>;
  };

  const accountHolderChip = ({ teamMemberUser }) => {
    if (teamMemberUser.accountHolder) {
      return (
        <div className="flex">
          <Tooltip target={`.account-holder-chip-tooltip-${teamMemberUser.id}`} />
          <Button
            key={`${teamMemberUser.id}-account-holder-chip`}
            severity="success"
            icon="pi pi-check"
            data-pr-tooltip="Account Holder"
            data-pr-position="top"
            rounded
            text
            className={`account-holder-chip account-holder-chip-tooltip-${teamMemberUser.id} text-xs`}
          />
        </div>
      );
    }

    if (teamMemberUser.inviteStatus === 'SENT') {
      return (
        <div className="flex">
          <Tooltip target={`.account-holder-chip-tooltip-${teamMemberUser.id}`} />
          <Button
            key={`${teamMemberUser.id}-account-holder-chip`}
            icon="pi pi-send"
            data-pr-tooltip="Invite Sent"
            data-pr-position="top"
            rounded
            text
            className={`account-holder-chip account-holder-chip-tooltip-${teamMemberUser.id} text-xs`}
          />
        </div>
      );
    }

    return null;
  };

  const nameColumn = (teamMemberActor) => (
    <AvatarWithName
      id={teamMemberActor.id}
      initials={teamMemberActor.initials}
      avatarUrl={teamMemberActor.avatarUrl}
      text={teamMemberActor.name}
    >
      {accountHolderChip(teamMemberActor)}
    </AvatarWithName>
  );
  const emailLinkColumn = (teamMemberActor) => emailLink({ email: teamMemberActor.email });
  const phoneNumberColumn = (teamMemberActor) => phoneLink({ phone: teamMemberActor.phoneNumber });
  const instagramHandleColumn = (teamMemberActor) => instagramLink({ instagramHandle: teamMemberActor.instagramHandle });

  const hideCreateOrUpdateTeamMemberModal = () => {
    setShowCreateOrUpdateTeamMemberActorModal(false);
    setSelectedTeamMemberActor({});
  };

  return (
    <>
      <div className="flex justify-between w-full">
        <p className="font-bold text-xl">Team Members</p>
        <div className="flex items-center">
          <Button
            rounded
            className="h-8 w-8 mr-2"
            icon="pi pi-plus"
            onClick={() => setShowCreateOrUpdateTeamMemberActorModal(true)}
          />
          <IconField className="mr-2">
            <InputIcon className="pi pi-search" />
            <InputText placeholder="Search..." onInput={({ target }) => setSearchTerm(target.value)} />
          </IconField>
        </div>
      </div>
      <div className="content-with-nav-bar">
        <LazyPaginatedTable
          paginationBounds={paginationBounds}
          setPaginationBounds={setPaginationBounds}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          graphQLData={teamMemberActorData}
          clickable={false}
          pageSize={pageSize}
          onRowClick={handleRowClick}
          className="team-member-index-table cursor-pointer"
        >
          <Column field="name" filter header="Name" body={nameColumn} />
          <Column
            field="email"
            className="xxs:hidden md:table-cell"
            headerClassName="xxs:hidden md:table-cell"
            header="Email"
            body={emailLinkColumn}
          />
          <Column
            field="phoneNumber"
            className="xxs:hidden md:table-cell"
            headerClassName="xxs:hidden md:table-cell"
            header="Phone Number"
            body={phoneNumberColumn}
          />
          <Column
            field="instagramHandle"
            className="xxs:hidden md:table-cell"
            headerClassName="xxs:hidden md:table-cell"
            header="Instagram Handle"
            body={instagramHandleColumn}
          />
          <Column
            field="roles"
            headerClassName="xxs:hidden md:table-cell"
            header="Roles"
            body={mapRoles}
            className="xxs:hidden md:table-cell pr-0"
          />
        </LazyPaginatedTable>
      </div>
      <CreateOrUpdateTeamMemberModal
        show={showCreateOrUpdateTeamMemberActorModal}
        onHide={hideCreateOrUpdateTeamMemberModal}
        refetchTeamMembersAndGroups={refetchTeamMembersAndGroups}
        teamMemberActor={selectedTeamMemberActor}
        toastRef={toastRef}
        currentVendorName={currentVendorName}
      />
    </>
  );
}

export default TeamMemberIndexTable;
