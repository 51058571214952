import React, { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import map from 'lodash/map';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { confirmDialog } from 'primereact/confirmdialog';
import AddVendorModal from './AddVendorModal';
import VendorCard from './VendorCard';
import CreateDefaultContactModal from './CreateDefaultContactForm/Modal';
import UpdateEventVendorModal from './UpdateEventVendorForm/Modal';
import EventVendorProfileModal from './EventVendorProfile/Modal';
import { EVENT_VENDOR_QUERY, EVENT_VENDORS_QUERY, EVENT_INVITE_UPDATE_MUTATION } from './graphql';

const STATUSES_TO_HEADERS = {
  REQUESTED: 'Requested to Join',
  APPROVED: 'Active',
  ADDED: 'Needs Invite',
  INVITED: 'Invite Sent',
  REJECTED: 'Invite Rejected',
  REMOVED: 'Removed',
};
function VendorsTab() {
  const navigate = useNavigate();
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isRemovingSelf, setIsRemovingSelf] = useState(false);
  const [isCancellingInvite, setIsCancellingInvite] = useState(false);
  const [showCreateDefaultContactModal, setShowCreateDefaultContactModal] = useState(false);
  const [showUpdateEventVendorModal, setShowUpdateEventVendorModal] = useState(false);
  const [showEventVendorProfileModal, setShowEventVendorProfileModal] = useState(false);
  const [activeEventVendor, setActiveEventVendor] = useState(null);
  const [activeEventVendorId, setActiveEventVendorId] = useState(null);
  const [currentVendor, setCurrentVendor] = useState(null);
  const [eventVendors, setEventVendors] = useState([]);
  const [event, setEvent] = useState(null);
  const { eventId } = useParams();
  const toastRef = useRef(null);

  const {
    eventVendorsQueryLoading, eventVendorsQueryError, refetch: refetchEventVendors,
  } = useQuery(
    EVENT_VENDORS_QUERY,
    {
      variables: { filters: { id: eventId } },
      onCompleted: (data) => {
        const eventData = data.currentActor.events.edges[0].node;
        setCurrentVendor(data.currentVendor);
        setEventVendors(eventData.eventVendors);
        setEvent(eventData);
      },
      onError: ({ graphQLErrors }) => {
        graphQLErrors.map(({ message }) => (
          showErrorToast(toastRef, `Error fetching event vendors ${message}`)
        ));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const [updateEventInviteMutation] = useMutation(EVENT_INVITE_UPDATE_MUTATION, {
    onCompleted: () => {
      refetchEventVendors();

      if (isRemoving) {
        if (isRemovingSelf) {
          navigate('/app/events');
        } else {
          showSuccessToast(toastRef, 'Removed');
        }
      } else if (isCancellingInvite) {
        showSuccessToast(toastRef, 'Invite cancelled');
      } else {
        showSuccessToast(toastRef, 'Sent!');
      }

      setIsRemoving(false);
      setIsRemovingSelf(false);
    },
    onError: () => showErrorToast(toastRef, 'Something went wrong :('),
  });

  const updateEventInvite = ({ eventVendor, status = 'INVITED' }) => {
    updateEventInviteMutation({ variables: { input: { eventVendorId: eventVendor.id, status } } });
  };

  const confirmRemoveVendor = (eventVendor) => {
    let message = '';
    let header = '';

    if (eventVendor.vendor.id === currentVendor.id) {
      setIsRemovingSelf(true);
      message = `Are you sure you want to remove ${eventVendor.vendor.name} from this event? You will immediately lose access.`;
      header = 'Leave Event';
    } else if (eventVendor.status === 'APPROVED') {
      message = `Are you sure you want to remove ${eventVendor.vendor.name}? They will immmediately lose access to this event.`;
      header = 'Remove Vendor';
    } else if (eventVendor.status === 'INVITED') {
      message = `Are you sure you want to cancel sending invite to ${eventVendor.vendor.name}? They will not be able to access this event.`;
      header = 'Cancel Invite';
    } else {
      message = `Are you sure you want to remove ${eventVendor.vendor.name}? They will not be able to access to this event.`;
      header = 'Remove Vendor';
    }

    confirmDialog({
      key: `${eventVendor.id}-remove-link`,
      className: 'xl:w-3/12',
      message,
      dismissableMask: true,
      draggable: false,
      header,
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        if (['APPROVED', 'ADDED'].includes(eventVendor.status)) {
          setIsRemoving(true);
          updateEventInvite({ eventVendor, status: 'REMOVED' });
        } else {
          setIsCancellingInvite(true);
          updateEventInvite({ eventVendor, status: 'ADDED' });
        }
      },
    });
  };

  const [
    fetchActiveEventVendor, { refetch: refetchActiveEventVendor },
  ] = useLazyQuery(
    EVENT_VENDOR_QUERY,
    {
      onCompleted: (data) => {
        setCurrentVendor(data.currentVendor);
        setActiveEventVendor(data.currentActor.events.edges[0].node.eventVendors.find((eventVendor) => eventVendor.id === activeEventVendorId));
      },
      onError: ({ graphQLErrors }) => (
        graphQLErrors.map(({ message }) => (
          showErrorToast(toastRef, message)
        ))
      ),
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchActiveAndAllEventVendors = () => {
    refetchActiveEventVendor();
    refetchEventVendors();
  };

  if (eventVendorsQueryLoading || eventVendorsQueryError) { return null; }

  const createDefaultContact = (eventVendorId) => {
    fetchActiveEventVendor({ variables: { eventFilters: { id: eventId }, eventVendorFilters: { id: eventVendorId } } });
    setActiveEventVendorId(eventVendorId);
    setShowCreateDefaultContactModal(true);
  };

  const updateEventVendor = (eventVendorId) => {
    fetchActiveEventVendor({ variables: { eventFilters: { id: eventId }, eventVendorFilters: { id: eventVendorId } } });
    setActiveEventVendorId(eventVendorId);
    setShowUpdateEventVendorModal(true);
  };

  const showEventVendorProfile = (eventVendorId) => {
    fetchActiveEventVendor({ variables: { eventFilters: { id: eventId }, eventVendorFilters: { id: eventVendorId } } });
    setActiveEventVendorId(eventVendorId);
    setShowEventVendorProfileModal(true);
  };

  const confirmSendInvite = (eventVendor) => {
    const { primaryPointOfContactActor } = eventVendor;

    if (!primaryPointOfContactActor) { return createDefaultContact(eventVendor.id); }

    const statusAction = eventVendor.status === 'ADDED' ? 'Send' : 'Re-send';

    return confirmDialog({
      key: `${primaryPointOfContactActor.id}-invite-link`,
      message: `${statusAction} invite to ${primaryPointOfContactActor.name} at ${eventVendor.vendor.name}?`,
      className: 'xl:w-3/12',
      dismissableMask: true,
      draggable: false,
      header: 'Send Invite Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => updateEventInvite({ eventVendor }),
    });
  };

  const hideEventVendorProfile = () => {
    setActiveEventVendor(null);
    setShowEventVendorProfileModal(false);
  };

  /* eslint-disable no-param-reassign */
  const mappedEventVendorsByStatus = eventVendors.reduce((eventVendorsByStatus, eventVendor) => {
    eventVendorsByStatus[eventVendor.status] ||= [];
    eventVendorsByStatus[eventVendor.status].push(eventVendor);
    return eventVendorsByStatus;
  }, {});
  /* eslint-enable no-param-reassign */

  const showHeaders = Object.keys(mappedEventVendorsByStatus).length > 1;
  let i = 0;

  return (
    <>
      <div className="flex justify-between">
        <p className="font-bold text-xl">Vendors</p>
        {
          event?.isUpdatableByCurrentActor && (
            <Button
              rounded
              className="h-8 w-8"
              icon="pi pi-plus"
              onClick={() => setShowAddVendorModal(true)}
            />
          )
        }
      </div>
      <div className="content-with-nav-bar">
        {
          map(STATUSES_TO_HEADERS, (header, status) => {
            const mappedEventVendors = mappedEventVendorsByStatus[status];

            if (!mappedEventVendors) { return null; }

            i += 1;

            return (
              <div key={`${status}-vendors`}>
                { showHeaders && <p className={`text-gray font-bold ${i > 1 ? 'mt-4' : 'mt-0'}`}>{header}</p> }
                <div className="items-start flex flex-wrap mt-2 grid xxs:grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                  {
                    mappedEventVendors.map((eventVendor) => (
                      <div className="p-0" key={`${eventVendor.id}-event-vendor-card`}>
                        <VendorCard
                          key={`${eventVendor.id}-vendor-card`}
                          event={event}
                          eventVendor={eventVendor}
                          refetchEventVendors={refetchEventVendors}
                          toastRef={toastRef}
                          currentVendorId={currentVendor?.id}
                          updateEventVendor={updateEventVendor}
                          confirmSendInvite={confirmSendInvite}
                          confirmRemoveVendor={confirmRemoveVendor}
                          showEventVendorProfile={showEventVendorProfile}
                          setActiveEventVendor={setActiveEventVendor}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            );
          })
        }
      </div>
      <AddVendorModal
        show={showAddVendorModal}
        refetchEventVendors={refetchEventVendors}
        onHide={() => setShowAddVendorModal(false)}
        eventId={eventId}
        confirmSendInvite={confirmSendInvite}
        updateEventInvite={updateEventInvite}
        existingEventVendors={map(eventVendors, 'vendor')}
      />
      {
        activeEventVendor && (
          <>
            <CreateDefaultContactModal
              show={showCreateDefaultContactModal}
              onHide={() => setShowCreateDefaultContactModal(false)}
              refetchEventVendors={refetchActiveAndAllEventVendors}
              confirmSendInvite={confirmSendInvite}
              updateEventInvite={updateEventInvite}
              confirmRemoveVendor={confirmRemoveVendor}
              eventVendorId={activeEventVendor.id}
              vendorName={activeEventVendor.vendor.name}
              toastRef={toastRef}
            />
            <UpdateEventVendorModal
              show={showUpdateEventVendorModal}
              onHide={() => setShowUpdateEventVendorModal(false)}
              refetchEventVendors={refetchActiveAndAllEventVendors}
              eventVendor={activeEventVendor}
              currentVendor={currentVendor}
              toastRef={toastRef}
            />
            <EventVendorProfileModal
              show={showEventVendorProfileModal}
              updatable={event.isUpdatableByCurrentActor}
              onHide={hideEventVendorProfile}
              eventVendor={activeEventVendor}
              toastRef={toastRef}
            />
          </>
        )
      }
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default VendorsTab;
