import React from 'react';
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
import { mergeClassNames } from 'utils/styleUtils';

function AvatarWithName({
  id,
  avatarUrl,
  initials,
  text,
  subtext,
  className = '',
  onClick = () => {},
  avatarClassName = 'bg-info text-white',
  tooltip = null,
  textClassName = '',
  children,
  avatarSize = 'normal',
}) {
  const textClasses = classNames(
    { 'text-sm': subtext },
    textClassName,
  );

  return (
    <div
      onClick={onClick}
      className={mergeClassNames(`flex items-center text-nowrap min-w-min avatar-with-name rounded-md ${className}`)}
      key={`${id}-avatar-with-name`}
    >
      { tooltip && <Tooltip target={`.tooltip-avatar-${id}`} /> }
      <Avatar
        key={`${id}-avatar`}
        image={avatarUrl}
        label={initials}
        shape="circle"
        size={avatarSize}
        data-pr-tooltip={tooltip}
        data-pr-position="top"
        className={mergeClassNames(`mr-2 tooltip-avatar-${id} avatar-${avatarSize} ${avatarClassName}`)}
      />
      {
        text && (
          <div className="text-wrap">
            <p className={textClasses}>{ text }</p>
            { subtext && <p className="text-xs">{ subtext }</p> }
          </div>
        )
      }
      { children }
    </div>
  );
}

export default AvatarWithName;
