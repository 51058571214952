import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { Button } from 'primereact/button';
import EmailInput from 'components/Form/EmailInput';
import {
  CREATE_DEFAULT_CONTACT_MUTATION,
} from './graphql';

function CreateDefaultContactForm({
  hideModal, toastRef, eventVendorId, vendorName, updateEventInvite,
}) {
  const [shouldSendInvite, setShouldSendInvite] = useState(false);
  const {
    control, getValues,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const [createDefaultContactMutation] = useMutation(CREATE_DEFAULT_CONTACT_MUTATION, {
    onCompleted: async ({ eventVendorDefaultContactCreate }) => {
      if (shouldSendInvite) { updateEventInvite({ eventVendor: eventVendorDefaultContactCreate.eventVendor }); }

      hideModal();
      setShouldSendInvite(false);
      showSuccessToast(toastRef, 'Successfully added primary point of contact');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const onSubmit = ({ shouldSendInviteValue }) => {
    setShouldSendInvite(shouldSendInviteValue);
    createDefaultContactMutation({
      variables: { input: { email: getValues('email'), firstName: `${vendorName} Owner`, eventVendorId } },
    });
  };

  return (
    <div>
      <form>
        <EmailInput
          control={control}
          name="email"
          label="Email"
        />
        <div className="w-full flex justify-end">
          <Button
            size="small"
            label="Send Invite"
            icon="pi pi-envelope"
            severity="success"
            onClick={() => onSubmit({ shouldSendInviteValue: true })}
            type="button"
          />
        </div>
      </form>
    </div>
  );
}

export default CreateDefaultContactForm;
