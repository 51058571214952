import React from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';

import { generateRandomKey } from 'utils/keyUtils';
import DraggablePortal from 'components/DragAndDrop/DraggablePortal';
import Card from './Card';

export default function Lane({
  header, tasks, droppableId, onCardClick, showEventInfo, showAssignee = true, showEmpty = true, laneIdx,
}) {
  if (!tasks.length && !showEmpty) { return null; }

  return (
    <div className="min-w-80 mr-4 w-80">
      <div className="h-16">
        { header }
      </div>
      <Droppable key={`lane-${laneIdx}`} droppableId={droppableId}>
        {(droppableProvided) => (
          <div
            {...droppableProvided.droppableProps}
            className="task-board-lane scrollable"
            ref={droppableProvided.innerRef}
          >
            {
              tasks.map((task, idx) => (
                <Draggable key={`lane-${laneIdx}-task-${task.id}`} draggableId={`lane-${laneIdx}-task-${task.id}`} index={idx}>
                  {(draggableProvided, draggableSnapshot) => (
                    <DraggablePortal snapshot={draggableSnapshot}>
                      <div
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        ref={draggableProvided.innerRef}
                      >
                        <Card
                          key={`${task.id}-${generateRandomKey()}-lane-card`}
                          task={task}
                          onClick={onCardClick}
                          showEventInfo={showEventInfo}
                          showAssignee={showAssignee}
                        />
                      </div>
                    </DraggablePortal>
                  )}
                </Draggable>
              ))
            }
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}
