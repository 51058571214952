import gql from 'graphql-tag';

const UPDATE_EVENT_VENDOR_MUTATION = gql`
  mutation UpdateEventVendor($input: EventVendorUpdateMutationInput!) {
    eventVendorUpdate(input: $input) {
      eventVendor {
        id
      }
    }
  }
`;

export {
  UPDATE_EVENT_VENDOR_MUTATION,
};
