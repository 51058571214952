import React from 'react';
import { Dialog } from 'primereact/dialog';
import UpdateEventVendorForm from '.';

function UpdateEventVendorModal({
  show, onHide, refetchEventVendors, toastRef, eventVendor, currentVendor,
}) {
  return (
    <Dialog
      className="xl:w-7/12"
      header={eventVendor.vendor.name}
      visible={show}
      onHide={onHide}
      resizable={false}
      draggable={false}
      maximizable
      dismissableMask
    >
      <UpdateEventVendorForm
        refetchEventVendors={refetchEventVendors}
        hideModal={onHide}
        toastRef={toastRef}
        eventVendor={eventVendor}
        currentVendor={currentVendor}
      />
    </Dialog>
  );
}

export default UpdateEventVendorModal;
