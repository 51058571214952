import React from 'react';
import { Dialog } from 'primereact/dialog';
import EventInvite from 'components/EventInvite';

function EventInviteModal({
  toastRef, show, onHide, refetch, status, eventVendor, event,
}) {
  return (
    <Dialog
      className="xl:w-5/12"
      header="Send Event Invite"
      visible={show}
      onHide={onHide}
      dismissableMask
      resizable={false}
      draggable={false}
    >
      <EventInvite
        className="border-0"
        status={status}
        toastRef={toastRef}
        onSuccess={onHide}
        event={event}
        createdAt={eventVendor.inviteLastActionedAt}
        inviterActor={eventVendor.invitedByActor}
        requesterActor={eventVendor.requestedByActor}
        actionerActor={eventVendor.inviteLastActionedByActor}
        actionedActorOrVendor={eventVendor.vendor}
        eventVendorId={eventVendor.id}
        currentActorIsOnEvent
        currentActorIsActioner={eventVendor.currentActorIsLastInviteActioner}
        currentVendorIsActioner={eventVendor.currentVendorIsLastInviteActioner}
        approvalSubjectType={eventVendor.inviteApprovalSubjectType}
        currentVendorIsActioned={false}
        refetch={refetch}
      />
    </Dialog>

  );
}

export default EventInviteModal;
