import React from 'react';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';

const formatDateTimeStr = (dateTimeStr, format) => DateTime.fromISO(dateTimeStr).toFormat(format);

const titleize = (string) => (
  string
    .toLowerCase()
    .replace(/^[-_\s]*(.)/, (_, character) => character.toUpperCase())
    .replace(/[-_\s]+(.)/g, (_, character) => ` ${character.toUpperCase()}`)
);

const formatPhoneNumber = (phoneNumber) => phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

const emailLink = ({ email, showType = 'text', className = '' }) => {
  if (!email) { return null; }

  return (
    <a className={className} target="_blank" href={`mailto:${email}`} rel="noreferrer">
      { showType === 'text' && email }
      {
        showType === 'button' && (
          <Button
            text
            rounded
            icon="pi pi-envelope"
            tooltip={email}
            tooltipOptions={{ position: 'top' }}
          />
        )
      }
    </a>
  );
};

const phoneLink = ({
  phone, showType = 'text', className = '', buttonClassName = '',
}) => {
  if (!phone) { return null; }

  return (
    <a className={className} target="_blank" href={`tel:${phone}`} rel="noreferrer">
      { showType === 'text' && formatPhoneNumber(phone) }
      {
        showType === 'button' && (
          <Button
            className={buttonClassName}
            text
            rounded
            icon="pi pi-phone"
            tooltip={formatPhoneNumber(phone)}
            tooltipOptions={{ position: 'top' }}
          />
        )
      }
    </a>
  );
};
const instagramLink = ({
  instagramHandle, showType = 'text', className = '', buttonClassName = '',
}) => {
  if (!instagramHandle) { return null; }

  return (
    <a className={className} target="_blank" href={`https://www.instagram.com/${instagramHandle.replace('@', '')}`} rel="noreferrer">
      { showType === 'text' && instagramHandle }
      {
        showType === 'button' && (
          <Button
            className={buttonClassName}
            text
            rounded
            icon="pi pi-instagram"
            tooltip={instagramHandle}
            tooltipOptions={{ position: 'top' }}
          />
        )
      }
    </a>
  );
};

export {
  titleize,
  emailLink,
  phoneLink,
  instagramLink,
  formatDateTimeStr,
};
