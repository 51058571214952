import React from 'react';
import { useController } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { classNames } from 'primereact/utils';

import { mergeClassNames } from 'utils/styleUtils';

function EmailInput(props) {
  const {
    control, name, label, required = true, className, controllerProps, inputProps,
  } = props;

  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required,
      pattern: EMAIL_REGEX,
    },
    defaultValue: '',
    ...controllerProps,
  });

  const inputClasses = classNames(
    'block',
    'w-full',
    {
      'p-invalid': error,
    },
  );

  const renderEmailError = (fieldError) => {
    if (!fieldError) { return null; }

    let errorMessage = '';
    switch (fieldError.type) {
      case 'required':
        errorMessage = 'Email is required';
        break;
      case 'pattern':
        errorMessage = 'Invalid email address. E.g. example@email.com';
        break;
      default:
        break;
    }

    return <p className="text-danger mt-2">{errorMessage}</p>;
  };

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className="block mb-2">{label}</label> }
      <IconField>
        <InputIcon className="pi pi-envelope" />
        <InputText
          id={name}
          value={value || ''}
          onChange={onChange}
          className={inputClasses}
          {...inputProps}
        />
      </IconField>
      { renderEmailError(error) }
    </div>
  );
}

export default EmailInput;
