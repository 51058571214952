import gql from 'graphql-tag';

const CREATE_DEFAULT_CONTACT_MUTATION = gql`
  mutation CreateDefaultContact($input: EventVendorDefaultContactCreateMutationInput!) {
    eventVendorDefaultContactCreate(input: $input) {
      eventVendor {
        id
        status
        primaryPointOfContactActor {
          id
        }
      }
    }
  }
`;

export {
  CREATE_DEFAULT_CONTACT_MUTATION,
};
