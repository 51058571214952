import gql from 'graphql-tag';

const VENDORS_QUERY = gql`
  query vendors {
    vendorsSearch {
      id
      name
      categories
    }
  }
`;

const EVENT_INVITE_UPDATE_MUTATION = gql`
  mutation UpdateEventInvite($input: EventInviteUpdateMutationInput!) {
    eventInviteUpdate(input: $input) {
      eventInvite {
        id
        status
        event {
          id
        }
      }
    }
  }
`;

const EVENT_VENDORS_QUERY = gql`
  query EventVendors($filters: EventFilterInput) {
    currentVendor {
      id
      primaryPointOfContactActor {
        id
      }
    }
    currentActor {
      id
      events(filters: $filters) {
        edges {
          cursor
          node {
            id
            name
            startDate
            isUpdatableByCurrentActor
            eventVendors {
              id
              categories
              status
              isAdmin
              inviteLastActionedAt
              inviteApprovalSubjectType
              currentActorIsOnEvent
              currentVendorIsLastInviteActioner
              vendor {
                id
                name
                initials
                categories
                primaryPointOfContactActor {
                  id
                  name
                }
              }
              requestedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                  categories
                }
              }
              invitedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                }
              }
              inviteLastActionedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                }
              }
              primaryPointOfContactActor {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const EVENT_VENDOR_QUERY = gql`
  query EventVendor($eventFilters: EventFilterInput, $eventVendorFilters: EventVendorFilterInput) {
    currentVendor {
      id
      teamMemberActors {
        edges {
          node {
            id
            name
          }
        }
      }
      primaryPointOfContactActor {
        id
        name
        initials
        avatarUrl
        email
        phoneNumber
        actorType
      }
      defaultTaskAssigneeActor {
        id
        name
        initials
        avatarUrl
        email
        phoneNumber
        actorType
      }
      actors {
        edges {
          node {
            id
            name
            initials
            avatarUrl
          }
        }
      }
    }
    currentActor {
      id
      events(filters: $eventFilters) {
        edges {
          cursor
          node {
            id
            eventVendors(filters: $eventVendorFilters) {
              id
              notes
              categories
              status
              vendor {
                id
                name
                categories
                phoneNumber
                instagramHandle
                email
                address {
                  id
                  formatted
                  street1
                  street2
                  city
                  usState {
                    id
                    abbreviation
                  }
                  zipCode
                }
              }
              primaryPointOfContactActor {
                id
                name
                initials
                avatarUrl
                email
                phoneNumber
                actorType
              }
              secondaryPointOfContactActor {
                id
                name
                initials
                avatarUrl
                email
                phoneNumber
                actorType
              }
              dayOfPointOfContactActor {
                id
                name
                initials
                avatarUrl
                email
                phoneNumber
                actorType
              }
              defaultTaskAssigneeActor {
                id
                name
                avatarUrl
                initials
                email
                phoneNumber
                actorType
              }
              teamMemberActors {
                id
                name
                email
                phoneNumber
                instagramHandle
              }
            }
          }
        }
      }
    }
  }
`;

const EVENT_VENDOR_ADD_MUTATION = gql`
  mutation AddVendorToEvent($input: EventVendorAddMutationInput!) {
    eventVendorAdd(input: $input) {
      eventVendor {
        id
        status
        primaryPointOfContactActor {
          id
          email
          name
        }
        vendor {
          id
          name
        }
      }
    }
  }
`;

const VENDOR_CREATE_MUTATION = gql`
  mutation CreateVendor($input: VendorCreateMutationInput!) {
    vendorCreate(input: $input) {
      vendor {
        id
        name
        address {
          id
          street1
          street2
          city
          usState {
            id
            name
          }
          zipCode
        }
      }
    }
  }
`;

export {
  VENDORS_QUERY,
  EVENT_INVITE_UPDATE_MUTATION,
  EVENT_VENDORS_QUERY,
  EVENT_VENDOR_QUERY,
  EVENT_VENDOR_ADD_MUTATION,
  VENDOR_CREATE_MUTATION,
};
