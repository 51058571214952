import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Column } from 'primereact/column';

import Table from 'components/Table';
import { PERMISSIONS_ROLES_QUERY, PERMISSION_ROLE_UPDATE_MUTATION } from './graphql';

function PermissionsIndexTable() {
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const columnIndexForRole = (role) => roles.findIndex((r) => r.id === role.id) + 1;

  useEffect(() => {
    const currentPermissions = permissions.reduce((mappedPermissions, permission, idx) => {
      permission.roles.map((role) => mappedPermissions.push({ cellIndex: columnIndexForRole(role), rowIndex: idx }));
      return mappedPermissions;
    }, []);

    setSelectedPermissions(currentPermissions);
  }, [permissions.length]);

  const { loading } = useQuery(
    PERMISSIONS_ROLES_QUERY,
    {
      onCompleted: (data) => {
        setPermissions(data.currentVendor.teamMemberActorPermissions);
        setRoles(data.currentVendor.teamMemberActorRoles);
      },
    },
  );

  const [updatePermissionRoleMutation] = useMutation(
    PERMISSION_ROLE_UPDATE_MUTATION,
    { refetchQueries: ['CurrentUser'] },
  );

  const onCellSelect = ({ rowIndex, cellIndex }) => {
    updatePermissionRoleMutation({ variables: { input: { roleId: roles[cellIndex - 1].id, permissionId: permissions[rowIndex].id, value: true } } });
  };

  const onCellUnselect = ({ rowIndex, cellIndex }) => {
    updatePermissionRoleMutation({ variables: { input: { roleId: roles[cellIndex - 1].id, permissionId: permissions[rowIndex].id, value: false } } });
  };

  if (loading) { return null; }

  return (
    <>
      <div className="flex justify-between w-full">
        <p className="font-bold text-xl">Permissions</p>
      </div>
      <div className="content-with-nav-bar">
        <Table
          data={permissions}
          showGridlines
          cellSelection
          selectionMode="multiple"
          selection={selectedPermissions}
          onSelectionChange={(e) => setSelectedPermissions(e.value)}
          onCellSelect={onCellSelect}
          onCellUnselect={onCellUnselect}
          dataKey="id"
          isDataSelectable={(event) => (event.data.field !== 'name')}
          className="permissions-index-table w-full grid grid-cols-12"
          rowGroupMode="rowspan"
          groupRowsBy="name"
        >
          <Column className="col-span-8" header="Name" field="name" />
          { roles.map((role) => <Column key={`${role.name}-role`} className="col-span-2 permissions-table-role-header" header={role.name} />) }
        </Table>
      </div>
    </>
  );
}

export default PermissionsIndexTable;
