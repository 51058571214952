import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import replace from 'lodash/replace';
import kebabCase from 'lodash/kebabCase';
import last from 'lodash/last';
import { titleize } from 'utils/stringUtils';
import TeamMemberIndex from './TeamMembers';
import UserProfile from './UserProfile';
import VendorProfile from './VendorProfile';
import NotificationSettings from './NotificationSettings';

const TABS = ['user', 'notificationSettings', 'vendor', 'team-members'];
const TEAM_MEMBER_TABS = ['team-members', 'contacts', 'groups', 'permissions'];
function Settings({
  canUpdateVendor, canUpdateTeamMembers, canUpdateTeamMemberGroups, canUpdatePermissions, currentVendorName,
}) {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const path = useLocation().pathname;

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showMenu, setShowMenu] = useState(0);
  const [activeTeamMemberTab, setActiveTeamMemberTab] = useState(null);

  useEffect(() => {
    const tabFromPath = last(path.split('/'));

    if (TEAM_MEMBER_TABS.includes(tabFromPath)) {
      setActiveTabIndex(TABS.indexOf('team-members'));
      setActiveTeamMemberTab(tabFromPath);
    } else if (tabFromPath === 'settings') {
      setActiveTabIndex(TABS.indexOf('user'));
    } else {
      setActiveTabIndex(TABS.indexOf(tabFromPath));
    }
  }, []);

  const onTeamMemberTabChange = (tabName) => {
    navigate(replace(path, TABS[activeTabIndex], tabName));
    setActiveTabIndex(TABS.indexOf('team-members'));
    setActiveTeamMemberTab(tabName);
    menuRef.current.hide({});
  };

  const onTabChange = ({ index }) => {
    navigate(replace(path, TABS[activeTabIndex], TABS[index]));
    setActiveTabIndex(index);
    menuRef.current.hide({});
  };

  let tabs = [
    { label: 'User Profile', icon: 'pi pi-fw pi-user', command: () => onTabChange({ index: 0 }) },
    { label: 'Notification Settings', icon: 'pi pi-fw pi-bell', command: () => onTabChange({ index: 1 }) },
    { label: 'Vendor Profile', icon: 'pi pi-fw pi-building', command: () => onTabChange({ index: 2 }) },
    { label: 'Team Members', icon: 'pi pi-fw pi-users', teamMemberDropdown: true },
  ];
  let teamMemberTabs = [
    {
      label: 'Team Members', icon: 'pi pi-user', tabName: 'team-members', command: () => onTeamMemberTabChange('team-members'),
    },
    {
      label: 'Default Contacts', icon: 'pi pi-check-square', tabName: 'contacts', command: () => onTeamMemberTabChange('contacts'),
    },
    {
      label: 'Groups', icon: 'pi pi-users', tabName: 'groups', command: () => onTeamMemberTabChange('groups'),
    },
    {
      label: 'Permissions', icon: 'pi pi-fw pi-lock', tabName: 'permissions', command: () => onTeamMemberTabChange('permissions'),
    },
  ];

  if (!canUpdateVendor) {
    tabs = tabs.filter((tab) => tab.label !== 'Vendor Profile');
    teamMemberTabs = teamMemberTabs.filter((tab) => tab.label !== 'Default Contacts');
  }

  if (!canUpdateTeamMembers) {
    tabs = tabs.filter((tab) => tab.label !== 'Team Members');
    teamMemberTabs = teamMemberTabs.filter((tab) => tab.label !== 'Team Members');
  }

  if (!canUpdateTeamMemberGroups) {
    teamMemberTabs = teamMemberTabs.filter((tab) => tab.label !== 'Groups');
  }

  if (!canUpdatePermissions) {
    teamMemberTabs = teamMemberTabs.filter((tab) => tab.label !== 'Permissions');
  }

  const settingsContent = () => {
    switch (activeTabIndex) {
      case 0:
        return (
          <UserProfile />
        );
      case 1:
        return (
          <NotificationSettings />
        );
      case 2:
        return (
          canUpdateVendor && <VendorProfile />
        );
      case 3:
        return (
          teamMemberTabs.length && <TeamMemberIndex tab={activeTeamMemberTab} currentVendorName={currentVendorName} />
        );
      default:
        return null;
    }
  };

  const header = (tabItem) => {
    if (tabItem.teamMemberDropdown) {
      return (
        <SplitButton
          text
          onClick={() => setActiveTeamMemberTab(teamMemberTabs[0]?.tabName)}
          label={titleize(tabItem.label)}
          icon={tabItem.icon}
          className="p-0"
          buttonClassName={`${activeTabIndex === TABS.indexOf('team-members') ? 'text-primary' : 'text-default'}`}
          menuButtonClassName={`${activeTabIndex === TABS.indexOf('team-members') ? 'text-primary' : 'text-default'}`}
          menuClassName="w-max"
          model={teamMemberTabs}
        />
      );
    }
    return (
      <div>
        <i className={`${tabItem.icon} mr-2`} />
        { tabItem.label }
      </div>
    );
  };

  const mapTabs = (items) => items.map((tabItem) => {
    if (tabItem.teamMemberDropdown) {
      return {
        label: tabItem.label,
        icon: tabItem.icon,
        items: mapTabs(teamMemberTabs),
      };
    }
    return {
      label: tabItem.label,
      icon: tabItem.icon,
      command: tabItem.command,
    };
  });

  const toggleMenu = (event) => {
    if (showMenu) {
      menuRef.current.hide(event);
    } else {
      menuRef.current.show(event);
    }
  };

  return (
    <div>
      <div className="xxs:hidden md:block">
        <p className="font-semibold text-xl text-gray-800 mr-4">Settings</p>
        <TabView activeIndex={activeTabIndex} onTabChange={onTabChange} className="settings-tab mt-2">
          {
            tabs.map((tabItem) => (
              <TabPanel
                key={`${tabItem.label}-settings-tab`}
                header={header(tabItem)}
                headerClassName={`flex items-center settings--tab-nav ${kebabCase(tabItem.label)}`}
              >
                { settingsContent() }
              </TabPanel>
            ))
          }
        </TabView>
      </div>
      <div className="md:hidden lg:hidden">
        <p className="font-semibold text-xl text-gray-800">Settings</p>
        <SplitButton
          ref={menuRef}
          onClick={toggleMenu}
          model={mapTabs(tabs)}
          className="text-sm settings-tab-slide-menu mb-6"
          buttonClassName="bg-none p-0 border-none"
          onHide={() => setShowMenu(false)}
          onShow={() => setShowMenu(true)}
          menuButtonClassName="hidden"
          severity="contrast"
          label={<Button icon="pi pi-bars" size="small" />}
        />
        { settingsContent() }
      </div>
    </div>
  );
}

Settings.defaultProps = {};

Settings.propTypes = {};

export default Settings;
