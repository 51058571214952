import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { DataView } from 'primereact/dataview';
import { useForm } from 'react-hook-form';
import { Avatar } from 'primereact/avatar';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { showErrorToast } from 'utils/toastUtils';

import EditableTextArea from 'components/Form/EditableFields/TextArea';
import { mapInfo } from 'utils/displayUtils';
import { emailLink, phoneLink, instagramLink } from 'utils/stringUtils';
import { EVENT_VENDOR_UPDATE_MUTATION } from './graphql';

const mappedRoleToDisplay = {
  defaultTaskAssigneeActor: 'Tasks',
  primaryPointOfContactActor: 'Primary',
  secondaryPointOfContactActor: 'Secondary',
  dayOfPointOfContactActor: 'Day of',
};

function EventVendorProfile({ updatable, toastRef, eventVendor }) {
  const [isEditing, setIsEditing] = useState(false);
  const {
    id,
    vendor,
    defaultTaskAssigneeActor,
    primaryPointOfContactActor,
    secondaryPointOfContactActor,
    dayOfPointOfContactActor,
    notes,
  } = eventVendor;

  const { control, getValues, setValue } = useForm({
    defaultValues: {
      notes,
    },
  });

  const [updateEventVendorMutation] = useMutation(EVENT_VENDOR_UPDATE_MUTATION, {
    onCompleted: ({ eventVendorUpdate }) => { setValue('notes', eventVendorUpdate.eventVendor.notes); },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  /* eslint-disable no-param-reassign */
  const mappedContactActors = reduce({
    primaryPointOfContactActor,
    secondaryPointOfContactActor,
    dayOfPointOfContactActor,
    defaultTaskAssigneeActor,
  }, (mappedContacts, actor, contactRole) => {
    if (actor) {
      const role = mappedRoleToDisplay[contactRole];
      const alreadyMappedContact = mappedContacts.find((contact) => contact.id === actor.id);

      if (alreadyMappedContact) {
        if (role) { alreadyMappedContact.roles.push(role); }
      } else {
        if (role) { actor.roles = [role]; }

        mappedContacts.push(actor);
      }
    }

    return mappedContacts;
  }, []);
  /* eslint-enable no-param-reassign */

  const teamMemberTemplate = (contactActor) => (
    <div className="col-span-12 mt-4">
      <div className="flex pb-2">
        <Avatar label={contactActor.initials} image={contactActor.avatarUrl} size="large" shape="circle" className="mr-2 bg-info text-white" />
        <div className="flex justify-between items-center flex-1">
          <div className="flex flex-col items-start">
            <div className="flex items-center text-gray-800">
              <p key={`${contactActor.id}-actor-name`}>{ contactActor.name } ({contactActor.roles.join(', ')})</p>
            </div>
            <p className="text-sm">{ contactActor.actorType === 'TEAM_MEMBER' ? 'Admin' : 'Group' }</p>
          </div>
          <div className="flex team-member-contact-info text-sm">
            { contactActor.email && emailLink({ email: contactActor.email, showType: 'button', showTooltip: true }) }
            { contactActor.phoneNumber && phoneLink({ phone: contactActor.phoneNumber, showType: 'button', showTooltip: true }) }
            {
              contactActor.instagramHandle && (
                instagramLink({ instagramHandle: contactActor.instagramHandle, showType: 'button', showTooltip: true })
              )
            }
          </div>
        </div>
      </div>
    </div>
  );

  const mappedTeamMembers = () => {
    if (isEmpty(mappedContactActors)) { return null; }

    return (
      <div className="border-t mt-6 border-gray-200">
        <p className="m-0 mt-4 text-lg text-gray-800 font-semibold">Event Team Members</p>
        <p className="m-0 mt-4 text-gray-800 font-semibold">Contacts</p>
        <DataView value={mappedContactActors} itemTemplate={teamMemberTemplate} />
      </div>
    );
  };

  const updateEventVendor = (data) => {
    updateEventVendorMutation({
      variables: { input: { id, notes: data.notes } },
    });
  };

  const notesField = () => (
    <div className="border-t mt-6 border-gray-200">
      <p className="m-0 my-4 text-lg text-gray-800 font-semibold">Notes</p>
      <EditableTextArea
        formats={['bold', 'italic', 'underline', 'strike']}
        name="notes"
        updateFieldName="notes"
        textViewClassName="mb-0"
        updatable={updatable}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        onUpdate={updateEventVendor}
        control={control}
        setValue={setValue}
        getValues={getValues}
        placeholder="Add notes..."
      />
    </div>
  );

  return (
    <div className="event-vendor-profile" key={`${eventVendor.id}-event-vendor-profile`}>
      <p className="mb-2 mt-4 text-gray-800 font-semibold">Vendor Info</p>
      { mapInfo({
        data: vendor.address || {}, field: 'address', isFormatted: true, icon: 'map-marker', className: 'text-default text-sm mb-1',
      }) }
      { mapInfo({
        data: vendor, field: 'email', icon: 'envelope', className: 'text-default text-sm mb-1',
      }) }
      { mapInfo({
        data: vendor, field: 'phoneNumber', icon: 'phone', className: 'text-default text-sm mb-1',
      }) }
      { mapInfo({
        data: vendor, field: 'instagramHandle', icon: 'instagram', className: 'text-default text-sm',
      }) }
      { mappedTeamMembers() }
      { notesField() }
    </div>
  );
}

export default EventVendorProfile;
