import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { titleize } from 'utils/stringUtils';
import { CATEGORY_BACKGROUND_COLOR_MAPPING } from 'constants/colors';
import EventVendorProfile from '.';

function EventVendorProfileModal({
  show, onHide, toastRef, eventVendor, updatable,
}) {
  const modalHeader = () => (
    <div className="line-height-2">
      <div className="text-gray-800">{ eventVendor.vendor.name }</div>
      <div>
        {
          eventVendor.categories.map((category) => (
            <Tag
              key={`${category}-tag`}
              className={`mr-1 ${CATEGORY_BACKGROUND_COLOR_MAPPING[category] || CATEGORY_BACKGROUND_COLOR_MAPPING.default}`}
              value={titleize(category)}
            />
          ))
        }
      </div>
    </div>
  );

  return (
    <Dialog
      key={`${eventVendor.id}-event-vendor-profile-modal`}
      header={modalHeader()}
      className="xl:w-7/12"
      visible={show}
      onHide={onHide}
      resizable={false}
      draggable={false}
      dismissableMask
      maximizable
    >
      <EventVendorProfile
        hideModal={onHide}
        toastRef={toastRef}
        updatable={updatable}
        eventVendor={eventVendor}
      />
    </Dialog>
  );
}

export default EventVendorProfileModal;
