import React from 'react';

import {
  formatDateTimeStr, emailLink, phoneLink, instagramLink,
} from 'utils/stringUtils';

const formatTimes = ({ startTime, endTime }) => {
  const formattedStartTime = startTime ? formatDateTimeStr(startTime, 'h:mm a') : '';
  const formattedEndTime = formatDateTimeStr(endTime, 'h:mm a');

  if (endTime) {
    return (
      `${formattedStartTime} - ${formattedEndTime}`
    );
  }
  return formattedStartTime;
};

const addressLine = (address, field) => {
  if (!address) { return ''; }

  let fieldInfo = address[field];

  if (field === 'cityAndState') {
    if (address.city && address.usState) {
      fieldInfo = `${address.city}, ${address.usState.abbreviation}`;
    } else {
      fieldInfo = address.city || address.usState?.abbreviation;
    }
  }

  return fieldInfo || '';
};

/* eslint-disable max-len */
const formattedAddress = (address) => (
  `${addressLine(address, 'street1')} ${addressLine(address, 'street2')}\n ${addressLine(address, 'cityAndState')} ${addressLine(address, 'zipCode')}`
);
/* eslint-enable max-len */

const mapInfo = ({
  data, field, isFormatted = false, icon, className = 'text-sm',
}) => {
  const fieldData = isFormatted ? data.formatted : data[field];

  if (!fieldData) { return null; }

  let formattedField;

  switch (field) {
    case 'address':
      formattedField = (
        <a
          className={`text-with-line-breaks ${className}`}
          target="_blank"
          href={`http://www.google.com/search?q=${formattedAddress(data[field])}`}
          rel="noreferrer"
        >
          { isFormatted ? fieldData : formattedAddress(fieldData) }
        </a>
      );
      break;
    case 'email':
      formattedField = emailLink({ email: fieldData, className });
      break;
    case 'phoneNumber':
      formattedField = phoneLink({ phone: fieldData, className });
      break;
    case 'instagramHandle':
      formattedField = instagramLink({ instagramHandle: fieldData, className });
      break;
    default:
      formattedField = fieldData;
      break;
  }

  return (
    <div className={`flex items-center ${className}`}>
      <i className={`pi pi-${icon} mr-2 ${className}`} />
      <p className="m-0">{ formattedField }</p>
    </div>
  );
};

export {
  mapInfo,
  formatTimes,
};
