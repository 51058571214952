import gql from 'graphql-tag';

const EVENT_VENDOR_UPDATE_MUTATION = gql`
  mutation UpdateEventVendor($input: EventVendorUpdateMutationInput!) {
    eventVendorUpdate(input: $input) {
      eventVendor {
        id
        notes
      }
    }
  }
`;

export {
  EVENT_VENDOR_UPDATE_MUTATION,
};
