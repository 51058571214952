import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { confirmDialog } from 'primereact/confirmdialog';
import { Avatar } from 'primereact/avatar';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';

import { clickableTarget } from 'utils/formUtils';
import { emailLink, phoneLink, instagramLink } from 'utils/stringUtils';
import { showErrorToast, showSuccessToast } from 'utils/toastUtils';
import FindOrCreateHostModal from './FindOrCreateHostModal';
import HostModal from './HostModal';
import { EVENT_INVITE_UPDATE_MUTATION } from './graphql';

function HostActorsCard({
  eventHostActors, currentEventHostActor, event, refetchEventOverview, toastRef,
}) {
  const navigate = useNavigate();
  const [showCreateHostModal, setShowCreateHostModal] = useState(false);
  const [isRemovingSelf, setIsRemovingSelf] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [activeEventHostActor, setActiveEventHostActor] = useState(null);
  const [showActiveEventHostActorModal, setShowActiveEventHostActorModal] = useState(false);
  const [isCancellingInvite, setIsCancellingInvite] = useState(false);
  const adminTag = () => <Tag icon="pi pi-star" className="bg-white text-gray-800 p-0" value="Admin" />;

  const { id: eventId } = event;

  useEffect(() => {
    if (activeEventHostActor) {
      setShowActiveEventHostActorModal(true);
    } else {
      setShowActiveEventHostActorModal(false);
    }
  }, [activeEventHostActor]);

  const [updateEventInviteMutation] = useMutation(EVENT_INVITE_UPDATE_MUTATION, {
    onCompleted: () => {
      refetchEventOverview();

      if (isRemoving) {
        if (isRemovingSelf) {
          navigate('/app/events');
        } else {
          showSuccessToast(toastRef, 'Removed');
        }
      } else if (isCancellingInvite) {
        showSuccessToast(toastRef, 'Invite cancelled');
      } else {
        showSuccessToast(toastRef, 'Sent!');
      }

      setIsRemoving(false);
      setIsRemovingSelf(false);
    },
    onError: () => showErrorToast(toastRef, 'Something went wrong :('),
    refetchQueries: ['Events'],
  });
  const updateEventInvite = ({ eventHostActor, status = 'INVITED' }) => {
    updateEventInviteMutation({ variables: { input: { eventHostActorId: eventHostActor.id, status } } });
  };
  const confirmRemoveHost = ({ eventHostActor }) => {
    let message = '';
    let header = '';

    if (eventHostActor.id === currentEventHostActor?.id) {
      setIsRemovingSelf(true);
      message = 'Are you sure you want to remove yourself from this event? You will immediately lose access.';
      header = 'Leave Event';
    } else if (eventHostActor.status === 'APPROVED') {
      message = `Are you sure you want to remove ${eventHostActor.hostActor.name}? They will immmediately lose access to this event.`;
      header = 'Remove Host';
    } else if (eventHostActor.status === 'INVITED') {
      message = `Are you sure you want to cancel sending invite to ${eventHostActor.hostActor.name}? They will not be able to access this event.`;
      header = 'Cancel Invite';
    } else {
      message = `Are you sure you want to remove ${eventHostActor.hostActor.name}? They will not be able to access this event.`;
      header = 'Remove Host';
    }

    confirmDialog({
      key: `${eventHostActor.id}-remove-link`,
      className: 'xl:w-3/12',
      message,
      dismissableMask: true,
      draggable: false,
      header,
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        if (['APPROVED', 'ADDED'].includes(eventHostActor.status)) {
          setIsRemoving(true);
          updateEventInvite({ eventHostActor, status: 'REMOVED' });
        } else {
          setIsCancellingInvite(true);
          updateEventInvite({ eventHostActor, status: 'ADDED' });
        }
      },
    });
  };
  const confirmSendInvite = ({ eventHostActor }) => {
    const statusAction = eventHostActor.status === 'ADDED' ? 'Send' : 'Re-send';

    const { hostActor } = eventHostActor;

    return confirmDialog({
      key: `${eventHostActor.id}-invite-link`,
      message: `${statusAction} invite to ${hostActor.name}?`,
      className: 'xl:w-3/12',
      dismissableMask: true,
      draggable: false,
      header: 'Send Invite Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => updateEventInvite({ eventHostActor }),
    });
  };

  const eventInviteActionButton = ({ eventHostActor }) => {
    if (!event.isUpdatableByCurrentActor) { return null; }

    if (['APPROVED', 'INVITED'].includes(eventHostActor.status)) {
      let tooltip = '';

      if (eventHostActor.status === 'APPROVED') {
        if (eventHostActor.id === currentEventHostActor?.id) {
          tooltip = 'Leave';
        } else {
          tooltip = 'Remove';
        }
      } else {
        tooltip = 'Cancel Invite';
      }

      return (
        <Button
          tooltip={tooltip}
          className="ml-1 h-min hover:h-max p-0"
          tooltipOptions={{ position: 'top' }}
          severity="danger"
          text
          icon="pi pi-times"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => confirmRemoveHost({ eventHostActor })}
        />
      );
    }

    return (
      <div className="flex">
        <Button
          tooltip="Send Invite"
          className="ml-1 h-min hover:h-max py-0 px-2 max-w-min"
          tooltipOptions={{ position: 'top' }}
          text
          icon="pi pi-send"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => confirmSendInvite({ eventHostActor })}
        />
        <Button
          tooltip="Remove"
          className="max-w-min h-min hover:h-max py-0 px-2"
          tooltipOptions={{ position: 'top' }}
          severity="danger"
          text
          icon="pi pi-times"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => confirmRemoveHost({ eventHostActor })}
        />
      </div>
    );
  };

  const findEventHostActor = ({ hostActorId }) => eventHostActors.find((eventHostActor) => eventHostActor.hostActor.id === hostActorId);

  const eventHostActorTemplate = (eventHostActor) => {
    const { hostActor } = eventHostActor;

    const handleActorClick = (e) => {
      if (!clickableTarget(e.target.className)) {
        setActiveEventHostActor(findEventHostActor({ hostActorId: hostActor.id }));
      }
    };

    return (
      <div className="col-span-12 mt-4 border-none cursor-pointer" key={hostActor.id} onClick={handleActorClick}>
        <div className="flex pb-2">
          <Avatar label={hostActor.initials} image={hostActor.avatarUrl} size="large" shape="circle" className="mr-2 bg-info text-white" />
          <div className="flex justify-between items-center flex-1">
            <div className="flex flex-col items-start">
              <div className="flex flex-col items-start text-gray-800">
                <div className="flex items-center">
                  <p key={`${hostActor.id}-actor-name`}>{ hostActor.name }</p>
                  { eventInviteActionButton({ eventHostActor }) }
                </div>
                { eventHostActor.isAdmin && adminTag() }
              </div>
            </div>
            <div className="flex team-member-host-info text-sm">
              { hostActor.email && emailLink({ email: hostActor.email, showType: 'button', showTooltip: true }) }
              { hostActor.phoneNumber && phoneLink({ phone: hostActor.phoneNumber, showType: 'button', showTooltip: true }) }
              {
                hostActor.instagramHandle && (
                  instagramLink({ instagramHandle: hostActor.instagramHandle, showType: 'button', showTooltip: true })
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card className="h-full event-overview--host-actors-card w-full">
      <div className="flex flex-col justify-between h-full items-start w-full">
        <div className="w-full">
          <div className="flex w-full items-center justify-between">
            <div className="font-semibold mb-4">Hosts</div>
            <div className="flex items-center justify-center bg-blue-100 rounded h-8 w-8">
              <i className="pi pi-users text-blue-500 text-xl" />
            </div>
          </div>
          { eventHostActors.length ? <DataView value={eventHostActors} itemTemplate={eventHostActorTemplate} /> : null }
        </div>
      </div>
      <div className="flex justify-end w-full -mt-9 xxs:pb-20">
        {
          event.isUpdatableByCurrentActor && (
            <Button
              label="Add Host"
              icon="pi pi-plus"
              outlined
              severity="info"
              className="create-host-button"
              type="button"
              size="small"
              onClick={() => setShowCreateHostModal(true)}
            />
          )
        }
      </div>
      <FindOrCreateHostModal
        show={showCreateHostModal}
        onHide={() => setShowCreateHostModal(false)}
        eventId={eventId}
        eventHostActors={eventHostActors}
        refetchEventOverview={refetchEventOverview}
      />
      {
        activeEventHostActor && (
          <HostModal
            show={showActiveEventHostActorModal}
            onHide={() => setActiveEventHostActor(null)}
            updatable={event.isUpdatableByCurrentActor || activeEventHostActor.id === currentEventHostActor.id}
            eventHostActor={activeEventHostActor}
            refetchEventOverview={refetchEventOverview}
          />
        )
      }
    </Card>
  );
}

export default HostActorsCard;
