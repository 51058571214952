import React from 'react';
import { Dialog } from 'primereact/dialog';
import CreateDefaultContactForm from '.';

function CreateDefaultContactModal({
  show, onHide, refetchEventVendors, toastRef, vendorName, eventVendorId, updateEventInvite,
}) {
  const hideModal = () => {
    refetchEventVendors();
    onHide();
  };

  return (
    <Dialog
      header={`Create primary point of contact for ${vendorName}`}
      className="xl:w-5/12"
      visible={show}
      onHide={hideModal}
      dismissableMask
      resizable={false}
      draggable={false}
      maximizable
    >
      <CreateDefaultContactForm
        hideModal={hideModal}
        toastRef={toastRef}
        eventVendorId={eventVendorId}
        vendorName={vendorName}
        updateEventInvite={updateEventInvite}
      />
    </Dialog>
  );
}

export default CreateDefaultContactModal;
