import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';

import { showErrorToast } from 'utils/toastUtils';
import { titleize } from 'utils/stringUtils';
import { vendorTextColorClassName, fileCategoryBackgroundColorClassName } from './helpers';
import ManageDisplaySettings from './ManageDisplaySettings';
import AddFileModal from './AddFileForm/Modal';
import FileModal from './FileModal';
import UploaderFileCard from './UploaderFileCard';
import CategoryFileCard from './CategoryFileCard';
import { FILES_QUERY } from './graphql';

const DEFAULT_DISPLAY_SETTINGS = {
  grouping: 'uploader',
};

function FilesTab() {
  const [currentVendor, setCurrentVendor] = useState(false);
  const [currentActor, setCurrentActor] = useState(false);
  const [eventFiles, setEventFiles] = useState([]);
  const [showAddFileModal, setShowAddFileModal] = useState(false);
  const [activeEventFileId, setActiveEventFileId] = useState(null);
  const [activeEventFile, setActiveEventFile] = useState(null);
  const [displaySettings, setDisplaySettings] = useState(JSON.parse(localStorage.getItem('filesDisplaySettings')) || DEFAULT_DISPLAY_SETTINGS);
  const displaySettingsRef = useRef(null);

  const { eventId } = useParams();
  const toastRef = useRef(null);

  const setSelectedGrouping = (grouping) => {
    setDisplaySettings({ ...displaySettings, grouping });
  };

  const showOnSummaryFilters = () => {
    if (displaySettings.showHidden) {
      return { showOnSummary: { in: [true, false] } };
    }
    return { showOnSummary: { in: [true] } };
  };

  useEffect(() => {
    window.localStorage.setItem('filesDisplaySettings', JSON.stringify(displaySettings));
  }, [displaySettings]);

  useEffect(() => {
    setActiveEventFile(eventFiles.find((eventFile) => eventFile.file.id === activeEventFileId));
  }, [activeEventFileId]);

  const {
    refetch: refetchFiles,
  } = useQuery(
    FILES_QUERY,
    {
      variables: { eventFilters: { id: eventId }, fileFilters: { ...showOnSummaryFilters() } },
      onCompleted: (data) => {
        const eventData = data.currentActor.events.edges[0].node;
        setCurrentVendor(data.currentVendor);
        setCurrentActor(data.currentActor);
        setEventFiles(eventData.eventUploadedFiles);
      },
      onError: ({ graphQLErrors }) => {
        graphQLErrors.map(({ message }) => (
          showErrorToast(toastRef, `Error fetching files ${message}`)
        ));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const setShowHidden = (showHidden) => {
    refetchFiles({ variables: { eventFilters: { id: eventId }, fileFilters: { ...showOnSummaryFilters() } } });
    setDisplaySettings({ ...displaySettings, showHidden });
  };

  const currentVendorOrActorName = currentVendor.name || currentActor.name;
  const fileUploader = (file) => file.createdByVendor || file.createdByActor;
  const sortCurrentVendorOrActorFirst = (name) => (name === currentVendorOrActorName ? 0 : 1);

  /* eslint-disable no-param-reassign */
  const groupedFilesByUploader = eventFiles.reduce((grouped, eventFile) => {
    const uploader = eventFile.file.createdByVendor || eventFile.file.createdByActor;

    grouped[uploader.name] ||= { eventFiles: [] };
    grouped[uploader.name].eventFiles.push(eventFile);
    grouped[uploader.name].category = uploader.categories?.[0];

    return grouped;
  }, []);

  const groupedFilesByCategory = eventFiles.reduce((grouped, eventFile) => {
    const category = eventFile.file.fileCategory;

    grouped[category] ||= { eventFiles: [] };
    grouped[category].eventFiles.push(eventFile);

    return grouped;
  }, {});
  /* eslint-enable no-param-reassign */

  const groupedCategoryFiles = () => (
    map(groupedFilesByCategory, ((data, category) => (
      <div className="mb-8">
        <Tag className={`${fileCategoryBackgroundColorClassName(category)} mb-4`} value={titleize(category)} />
        <div className="grid xxs:grid-cols-2 xs:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2 w-full cursor-pointer file-cards">
          {
            sortBy(data.eventFiles, ({ file }) => sortCurrentVendorOrActorFirst(fileUploader(file).name)).map((eventFile) => {
              const uploader = fileUploader(eventFile.file);

              return (
                <CategoryFileCard
                  setActiveEventFileId={setActiveEventFileId}
                  uploader={uploader}
                  toastRef={toastRef}
                  eventFile={eventFile}
                  refetchFiles={refetchFiles}
                />
              );
            })
          }
        </div>
      </div>
    )))
  );

  const uploaderFileDetails = ({ data, uploaderName }) => (
    <div className="mb-8">
      <p className={`font-semibold mb-4 ${vendorTextColorClassName(data.category)}`}>{uploaderName || 'Uploaded by you'}</p>
      <div className="grid xxs:grid-cols-2 xs:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2 w-full cursor-pointer file-cards">
        {
          data.eventFiles.map((eventFile) => (
            <UploaderFileCard
              toastRef={toastRef}
              setActiveEventFileId={setActiveEventFileId}
              eventFile={eventFile}
              refetchFiles={refetchFiles}
            />
          ))
        }
      </div>
    </div>
  );

  const groupedUploaderFiles = () => (
    sortBy(Object.keys(groupedFilesByUploader), (uploaderName) => sortCurrentVendorOrActorFirst(uploaderName)).map((uploaderName) => (
      uploaderFileDetails({ data: groupedFilesByUploader[uploaderName], uploaderName })
    ))
  );

  const groupedFiles = () => {
    if (displaySettings.grouping === 'uploader') {
      return groupedUploaderFiles();
    }
    return groupedCategoryFiles();
  };

  const groupingOptions = [
    {
      value: 'uploader',
      label: 'Uploader',
    },
    {
      value: 'category',
      label: 'File Category',
    },
  ];

  const hideModal = () => {
    setShowAddFileModal(false);
    refetchFiles();
  };

  return (
    <>
      <div className="flex justify-between">
        <p className="font-bold text-xl">Files</p>
        <div>
          <Button
            rounded
            className="h-8 w-8 mr-1 manage-display-settings-button"
            icon="pi pi-sliders-h"
            data-pr-tooltip="Manage Display Settings"
            data-pr-position="top"
            onClick={(e) => displaySettingsRef?.current?.toggle(e)}
          />
          <OverlayPanel ref={displaySettingsRef}>
            <ManageDisplaySettings
              groupingOptions={groupingOptions}
              displaySettings={displaySettings}
              setSelectedGrouping={setSelectedGrouping}
              setShowHidden={setShowHidden}
            />
          </OverlayPanel>
          <Button
            rounded
            className="h-8 w-8"
            icon="pi pi-plus"
            onClick={() => setShowAddFileModal(true)}
          />
        </div>
      </div>
      {
        !isEmpty(eventFiles) ? (
          <div className="content-with-nav-bar">
            { groupedFiles() }
          </div>
        ) : (
          <div className="content-with-nav-bar">
            <p className="text-gray">No files match the selected filters</p>
          </div>
        )
      }
      <AddFileModal
        show={showAddFileModal}
        files={map(eventFiles, 'file')}
        toastRef={toastRef}
        onHide={hideModal}
        refetch={refetchFiles}
      />
      {
        activeEventFile && (
          <FileModal
            show
            onHide={() => setActiveEventFileId(null)}
            eventFile={activeEventFile}
            file={activeEventFile.file}
            task={activeEventFile.task}
            toastRef={toastRef}
            refetchFiles={refetchFiles}
          />
        )
      }
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default FilesTab;
