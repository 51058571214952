import React from 'react';
import { Dialog } from 'primereact/dialog';
import CreateOrUpdateTeamMemberForm from '.';

function CreateOrUpdateTeamMemberModal({
  teamMemberActor, show, onHide, refetchTeamMembersAndGroups, toastRef, currentVendorName,
}) {
  return (
    <Dialog
      header={`${teamMemberActor ? 'Update' : 'Create'} Team Member`}
      className="xl:w-5/12"
      visible={show}
      onHide={onHide}
      resizable={false}
      draggable={false}
      dismissableMask
    >
      <CreateOrUpdateTeamMemberForm
        teamMemberActor={teamMemberActor}
        refetchTeamMembersAndGroups={refetchTeamMembersAndGroups}
        hideModal={onHide}
        currentVendorName={currentVendorName}
        toastRef={toastRef}
      />
    </Dialog>
  );
}

export default CreateOrUpdateTeamMemberModal;
