import React, { useState, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import StatusTag from 'containers/Events/StatusTag';
import { titleize } from 'utils/stringUtils';
import { CATEGORY_BACKGROUND_COLOR_MAPPING } from 'constants/colors';
import EventInviteModal from './EventInviteModal';

function VendorCard({
  currentVendorId,
  updateEventVendor,
  eventVendor,
  event,
  confirmSendInvite,
  confirmRemoveVendor,
  setActiveEventVendor,
  toastRef,
  refetchEventVendors,
  showEventVendorProfile,
}) {
  const [showEventInviteModal, setShowEventInviteModal] = useState(false);
  const [showInviteButton, setShowInviteButton] = useState(false);
  const menuRef = useRef(null);
  const {
    primaryPointOfContactActor: primaryPointOfContact,
    vendor,
    categories,
    status,
    isAdmin,
  } = eventVendor;
  const footerClasses = classNames(
    'border-t',
    'border-gray-200',
    'more-info-container',
    'w-full',
    'flex',
    'justify-between',
    'items-center',
    { 'pt-4': primaryPointOfContact },
    { 'pt-6': !primaryPointOfContact },
  );

  const adminTag = () => <Tag icon="pi pi-star" className="bg-white text-gray-800" value="Admin" />;

  const inviteButton = () => {
    let text = '';
    let icon = 'send';
    let onClick = () => confirmSendInvite(eventVendor);

    switch (status) {
      case 'REQUESTED':
        text = 'Respond to request';
        icon = '';
        onClick = () => setShowEventInviteModal(true);
        break;
      case 'INVITED':
      case 'REJECTED':
      case 'REMOVED': {
        text = 'Re-send invite';
        break;
      }
      default: {
        text = 'Send invite';
        break;
      }
    }

    return (
      <StatusTag
        status="ADDED"
        onMouseEnter={() => setShowInviteButton(true)}
        onMouseLeave={() => setShowInviteButton(false)}
        text={text}
        icon={icon}
        show={showInviteButton}
        onClick={onClick}
      />
    );
  };

  const statusTag = () => {
    if (isAdmin) { return adminTag(); }

    if (!event.isUpdatableByCurrentActor) { return null; }

    return (
      <>
        { inviteButton() }
        <StatusTag
          status={status}
          border={false}
          onMouseEnter={() => (status === 'ADDED' ? {} : setShowInviteButton(true))}
          onMouseLeave={() => (status === 'ADDED' ? {} : setShowInviteButton(false))}
          show={!showInviteButton}
          onClick={() => confirmSendInvite(eventVendor)}
        />
      </>
    );
  };

  const footer = () => (
    <div className={footerClasses}>
      <div>{ statusTag() }</div>
      {
        primaryPointOfContact && (
          <Button
            key={`${vendor.id}-more-info`}
            label="More Info"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              showEventVendorProfile(eventVendor.id, vendor.name);
            }}
            outlined
          />
        )
      }
    </div>
  );

  /* eslint-disable consistent-return */
  const onCardClick = () => {
    if (vendor.id === currentVendorId) {
      return updateEventVendor(eventVendor.id, vendor.name);
    } if (status === 'REQUESTED' && event.isUpdatableByCurrentActor) {
      return setShowEventInviteModal(true);
    } if (status === 'APPROVED') {
      return showEventVendorProfile(eventVendor.id, vendor.name);
    } if (event.isUpdatableByCurrentActor) {
      return confirmSendInvite(eventVendor);
    }
  };
  /* eslint-enable consistent-return */

  const categoriesMapping = () => (
    categories.map((category) => (
      <Tag
        key={`${vendor.id}-${category}`}
        value={titleize(category)}
        className={`${CATEGORY_BACKGROUND_COLOR_MAPPING[category] || CATEGORY_BACKGROUND_COLOR_MAPPING.default} mr-1`}
      />
    ))
  );

  const renderActions = () => {
    if (!event.isUpdatableByCurrentActor) { return null; }

    let label = '';
    const icon = status === 'APPROVED' ? 'trash' : 'times';

    if (status === 'APPROVED') {
      if (vendor.id === currentVendorId) {
        label = 'Leave';
      } else {
        label = 'Remove';
      }
    } else if (status === 'INVITED') {
      label = 'Cancel Invite';
    } else {
      label = 'Remove';
    }

    return (
      <>
        <Menu
          key={`${vendor.id}-card-actions`}
          model={[{
            label,
            icon: `pi pi-${icon}`,
            command: ({ originalEvent }) => {
              originalEvent.stopPropagation();
              confirmRemoveVendor(eventVendor);
            },
          }]}
          popup
          ref={menuRef}
          id="vendor-card-actions-menu"
          className="text-sm w-max"
        />
        <Button
          key={`${vendor.id}-card-actions-toggle`}
          icon="pi pi-bars"
          text
          size="small"
          className="p-0"
          onClick={(e) => {
            e.stopPropagation();
            menuRef.current.toggle(e);
          }}
          aria-controls="vendor-card-actions-menu"
          aria-haspopup
        />
      </>
    );
  };

  const header = () => (
    <div className="flex items-start justify-between h-24">
      <div className="flex items-start">
        <Avatar key={`${vendor.id}-avatar`} label={vendor.name[0]} size="xlarge" shape="circle" className="mr-2 bg-gray text-white" />
        <div>
          <p key={`${vendor.id}-vendor-name`} className="font-semibold m-0 text-gray-800"><span className="mr-2">{ vendor.name }</span></p>
          <div key={`${vendor.id}-vendor-categories`} className="font-medium">{ categoriesMapping() }</div>
        </div>
      </div>
      { renderActions() }
    </div>
  );

  const hideEventInviteModal = () => {
    setActiveEventVendor(null);
    setShowEventInviteModal(false);
  };

  return (
    <>
      <Card
        key={`${vendor.id}-card`}
        className="vendor-card rounded-md p-6 relative h-48 cursor-pointer"
        header={header()}
        footer={footer()}
        onClick={onCardClick}
      />
      <EventInviteModal
        event={event}
        eventVendor={eventVendor}
        status={status}
        show={showEventInviteModal}
        onHide={hideEventInviteModal}
        refetch={refetchEventVendors}
        toastRef={toastRef}
      />
    </>
  );
}

export default VendorCard;
