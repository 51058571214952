import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Dialog } from 'primereact/dialog';
import { useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { mapInfo } from 'utils/displayUtils';
import EditableTextArea from 'components/Form/EditableFields/TextArea';
import { EVENT_HOST_ACTOR_UPDATE_MUTATION } from './graphql';

function HostModal(props) {
  const [isEditing, setIsEditing] = useState(false);
  const {
    show,
    onHide,
    toastRef,
    eventHostActor,
    updatable,
    refetchEventOverview,
  } = props;

  const { hostActor, notes } = eventHostActor;

  const { control, getValues, setValue } = useForm({
    defaultValues: {
      notes,
    },
  });

  const [updateEventHostActorMutation] = useMutation(EVENT_HOST_ACTOR_UPDATE_MUTATION, {
    onCompleted: async () => {
      await refetchEventOverview();
      showSuccessToast(toastRef, 'Updated host!');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(() => showErrorToast(toastRef, 'Error updating host!'));
    },
  });

  const updateEventHostActor = (data) => {
    updateEventHostActorMutation({
      variables: { input: { id: eventHostActor.id, notes: data.notes } },
    });
  };

  const notesField = () => (
    <div className="border-t mt-6 border-gray-200">
      <p className="m-0 my-4 text-gray-800 font-semibold">Notes</p>
      <EditableTextArea
        formats={['bold', 'italic', 'underline', 'strike']}
        name="notes"
        updateFieldName="notes"
        textViewClassName="mb-0"
        updatable={updatable}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        onUpdate={updateEventHostActor}
        control={control}
        setValue={setValue}
        getValues={getValues}
        placeholder="Add notes..."
      />
    </div>
  );

  return (
    <Dialog
      className="xl:w-5/12"
      header="Host Details"
      visible={show}
      onHide={onHide}
      dismissableMask
      resizable={false}
      draggable={false}
    >
      <div className="event-host-actor-profile" key={`${eventHostActor.id}-event-host-actor-profile`}>
        { mapInfo({
          data: hostActor.address || {}, field: 'address', isFormatted: true, icon: 'map-marker', className: 'text-default text-sm mb-1',
        }) }
        { mapInfo({
          data: hostActor, field: 'email', icon: 'envelope', className: 'text-default text-sm mb-1',
        }) }
        { mapInfo({
          data: hostActor, field: 'phoneNumber', icon: 'phone', className: 'text-default text-sm mb-1',
        }) }
        { mapInfo({
          data: hostActor, field: 'instagramHandle', icon: 'instagram', className: 'text-default text-sm',
        }) }
        { notesField() }
      </div>
    </Dialog>
  );
}

export default HostModal;
